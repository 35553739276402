import { IGameTerm } from '@flarie/common';
import { create } from 'zustand';

interface Props {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  gameTerms: IGameTerm | null;
  setGameTerms: (value: IGameTerm) => void;
}

export const useGameTermStore = create<Props>((set) => ({
  isLoading: false,
  setIsLoading: (isLoading): void => set({ isLoading }),
  gameTerms: null,
  setGameTerms: (gameTerms): void => set({ gameTerms }),
}));
