/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import {
  FormInputType,
  GameCenterRewardType,
  IGameCenterPlayer,
  IGameCenterPopUp,
  IGameCenterReward,
  IGameCenterRewardFormInput,
  UserBaseIdentifierType,
} from '@flarie/common';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import isEmail from 'validator/lib/isEmail';
import { shallow } from 'zustand/shallow';
import { redeemsCoupon } from 'actions/GameCenterPlayerActions';
import { Input } from 'components/common/input/Input';
import { Modal } from 'components/common/modal/Modal';
import { CloseIcon } from 'components/icons/CloseIcon';
import { CoinIcons } from 'components/ui/CoinIcon';
import useGameCenterData from 'hooks/useGameCenterData';
import { ICouponRedeemDto } from 'interfaces/CouponRedeemDto';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { usePlayerStore } from 'store/PlayerStore';
import { checkKnownPlayer } from 'utils/check-known-player';
import { getPropertyKeyFromType } from 'utils/get-property-key-from-type';
import { addThousandSeparator, getPlayerIdIdentifierByGameCenter } from 'utils/helper';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import ClosePopup from './ClosePopup';
import { getPopUpStyle } from './styles';
import { UnknownPlayerInput } from './UnknownPlayerInput';

interface Props {
  reward: IGameCenterReward;
  openModal: boolean;
  onCloseModal: () => void;
}

export const RewardConfirmation: React.FC<Props> = ({ reward, openModal, onCloseModal }) => {
  const { rewardPrice, moduleId, gameCenterRewardId, formInputs, rewardType } = reward;
  const [knownEmail, setKnownEmail] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [purchaseSuccess, setPurchaseSuccess] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [rewardPurchaseError, setRewardPurchaseError] = useState<string>('');
  const [popup, rewardSettings, userBase] = useGameCenterSettingStore(
    (state) => [state.popup, state.rewardSettings, state.userBase],
    shallow,
  );
  const [gameCenterPlayer, setGameCenterPlayer] = usePlayerStore(
    (state) => [state.gameCenterPlayer, state.setGameCenterPlayer],
    shallow,
  );
  const { refetchGameCenter } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  if (!userBase) {
    return <div />;
  }

  const paramValuePair = useMemo(() => {
    const value = {} as any;
    if (!userBase || !gameCenterPlayer) return value;
    for (const param of formInputs) {
      value[getPropertyKeyFromType(param.type)] = gameCenterPlayer[getPropertyKeyFromType(param.type)] || '';
    }
    return value;
  }, [userBase]);

  const [parameterValues, setParameterValues] = useState(paramValuePair);

  const getUpdatedPlayerData = (): IGameCenterPlayer => {
    const player: Partial<IGameCenterPlayer> = {
      ...gameCenterPlayer,
    };

    if (formInputs.length && rewardType === GameCenterRewardType.FORM) {
      formInputs.forEach((item) => {
        player[getPropertyKeyFromType(item.type)] = parameterValues[getPropertyKeyFromType(item.type)];
      });
    }
    if (parameterValues.email) {
      player.email = parameterValues.email;
    }
    return player as IGameCenterPlayer;
  };

  const { isLoading, mutate } = useMutation(
    async () => {
      const { playerId, identifierValue } = getPlayerIdIdentifierByGameCenter(
        rewardSettings?.gameCenterId as string,
        userBase?.isEnable ? userBase?.identifierType : undefined,
      );

      const payload: ICouponRedeemDto = {
        playerId,
        identifierValue,
        gameCenterId: rewardSettings?.gameCenterId,
        moduleId,
        gameCenterRewardId,
        player: getUpdatedPlayerData(),
      } as ICouponRedeemDto;
      return redeemsCoupon(payload);
    },
    {
      onSuccess: () => {
        const player = getUpdatedPlayerData();
        if (player && formInputs.length && rewardType === GameCenterRewardType.FORM) {
          setGameCenterPlayer({ ...gameCenterPlayer, ...player });
        }
        setPurchaseSuccess(true);
        refetchGameCenter();
      },
      onError: ({ response }) => {
        const message = response?.data?.message || '';
        if (message.includes(`You don't have enough coin to purchase this reward`)) {
          setRewardPurchaseError(popup?.notEnoughCoinsText || '');
        } else if (message.includes(`Sorry reward purchaseLimit exit`)) {
          setRewardPurchaseError(popup?.prizeOutOfStockText || '');
        } else if (message.includes(`gameCenterRewardId should not be empty`)) {
          setRewardPurchaseError(popup?.coolDownText || '');
        } else {
          setRewardPurchaseError(message || '');
        }
      },
    },
  );

  const onConfirmPurchase = () => {
    mutate();
  };
  const onClickConfirmEmail = () => {
    if (userBase?.identifierType === UserBaseIdentifierType.USER_INPUT && !knownEmail) {
      const isValidEmail = isEmail(parameterValues[getPropertyKeyFromType(FormInputType.EMAIL)] || '');
      if (!isValidEmail) {
        setErrorMsg('Invalid e-mail format');
        return;
      }
      setErrorMsg('');
    }
    setShowConfirmation(true);
    setKnownEmail(true);
  };

  const onValueChange = (type: FormInputType, value: string | number) => {
    setParameterValues((prevState: any) => ({ ...prevState, [getPropertyKeyFromType(type)]: value }));
  };

  useEffect(() => {
    if (!gameCenterPlayer) {
      return;
    }
    const isKnownPlayer = checkKnownPlayer(gameCenterPlayer, userBase?.parameters || []);
    if (isKnownPlayer && gameCenterPlayer.email) {
      setKnownEmail(true);
    }
  }, [gameCenterPlayer]);
  const styles = getPopUpStyle(popup || ({} as IGameCenterPopUp));

  const onCloseErrorModal = () => {
    setRewardPurchaseError('');
    onCloseModal();
  };

  if (rewardPurchaseError) {
    return (
      <Modal
        isOpen={Boolean(rewardPurchaseError)}
        onClose={onCloseErrorModal}
        showFooter={false}
        showBack={false}
        showClose={false}
        contentClassName="justify-center !pt-0 !bg-black/[.70]"
        outerContentClassName="!pt-0"
      >
        <ClosePopup onCloseModal={onCloseErrorModal} popupText={rewardPurchaseError} />
      </Modal>
    );
  }

  const getForm = () => {
    // TODO: We will make it sub component
    if (purchaseSuccess) {
      return (
        <>
          <p className="mt-4 mb-8 whitespace-pre-wrap">{popup?.prizePurchaseICompletedText}</p>
          <DynamicFontLoader fontId={popup?.closeButtonStyle.font as string} fontName="close-button-font">
            <button
              onClick={onCloseModal}
              type="button"
              className="min-h-[56px] w-full"
              style={styles.closeButtonStyle}
            >
              {popup?.closeButtonText}
            </button>
          </DynamicFontLoader>
        </>
      );
    }
    if (showConfirmation || (knownEmail && (formInputs.length === 0 || rewardType !== GameCenterRewardType.FORM))) {
      return (
        <div className="ml-auto mr-auto max-w-[300px]">
          <div className="mb-5 flex items-center justify-center text-xl font-semibold">
            <CoinIcons size={20} /> &nbsp;{addThousandSeparator(rewardPrice)}
          </div>
          <p className="mb-8 whitespace-pre-wrap">{popup?.prizePurchaseItemText}</p>
          <DynamicFontLoader fontId={popup?.confirmButtonStyle.font as string} fontName="confirm-button-font">
            <button
              onClick={onConfirmPurchase}
              type="button"
              className="min-h-[56px] w-full"
              style={styles.confirmButtonStyle}
              disabled={isLoading}
            >
              {popup?.confirmButtonText}
            </button>
          </DynamicFontLoader>
        </div>
      );
    }
    return (
      <>
        <p className="mb-8 whitespace-pre-wrap text-lg">{rewardSettings?.rewardPopUpText}</p>
        {!knownEmail && (
          <>
            <Input
              placeholder="Email"
              error={errorMsg}
              value={parameterValues[getPropertyKeyFromType(FormInputType.EMAIL)]}
              onChange={(value) => onValueChange(FormInputType.EMAIL as FormInputType, value)}
            />
          </>
        )}
        {rewardType === GameCenterRewardType.FORM &&
          formInputs.map((param: IGameCenterRewardFormInput) => (
            <div key={param.type} className="mt-4">
              <UnknownPlayerInput
                {...param}
                value={parameterValues[getPropertyKeyFromType(param.type)]}
                onChange={(value) => onValueChange(param.type, value)}
              />
            </div>
          ))}
        <div className="mt-8"></div>
        <DynamicFontLoader fontId={popup?.confirmButtonStyle.font as string} fontName="confirm-button-font">
          <button
            onClick={onClickConfirmEmail}
            type="button"
            className="min-h-[56px] w-full"
            style={styles.confirmButtonStyle}
          >
            {popup?.confirmButtonText}
          </button>
        </DynamicFontLoader>
      </>
    );
  };
  return (
    <Modal
      isOpen={openModal}
      onClose={onCloseModal}
      showFooter={false}
      showBack={false}
      showClose={false}
      contentClassName="justify-center !pt-0 !bg-black/[.70]"
      outerContentClassName="!pt-0"
    >
      <div className="relative rounded-2xl px-6 pb-4 shadow-lg w-[70%] mx-auto" style={styles.pageStyle}>
        <button
          type="button"
          className="absolute right-3 top-3 ml-auto flex h-6 w-6 items-center justify-center rounded-lg active:scale-90 active:bg-ash"
          onClick={onCloseModal}
        >
          <CloseIcon color={styles?.pageStyle.color} />
        </button>
        <div className="pt-6 pb-2 text-center">{getForm()}</div>
      </div>
    </Modal>
  );
};
