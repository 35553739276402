import { IReplaceUrlParamsPayload } from 'interfaces/ReplaceUrlParams';
import { useParams } from 'react-router-dom';
import { getLocalGameCenterData } from 'services/LocalStorageService';
import { usePlayerStore } from 'store/PlayerStore';

export const usePopulateUrlParams = (): IReplaceUrlParamsPayload => {
  const { gameCenterId = '' } = useParams();
  const { gameCenterPlayer } = usePlayerStore();
  const gameCenterLocalData = getLocalGameCenterData(gameCenterId);

  const urlsParams: IReplaceUrlParamsPayload = {
    playerId: gameCenterPlayer?.playerId,
    uuid: gameCenterLocalData?.uuid,
    email: gameCenterLocalData?.email,
    phone: gameCenterLocalData?.phone,
  };

  return urlsParams;
};
