import { useEffect, useState } from 'react';
import {
  CustomizationKeyType,
  GameCenterStatusType,
  IGameCenter,
  IGameCenterPlayer,
  UserBaseIdentifierType,
} from '@flarie/common';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import * as uuid from 'uuid';
import { ThemeProvider } from 'styled-components';
import { Header } from 'components/header/Header';
import { ModuleList } from 'components/module-list/ModuleList';
import { PageWrapper } from 'components/page-wrapper/PageWrapper';
import { StickyUserAccess } from 'components/sticky-user-access/StickyUserAccess';
import DataLoading from 'components/ui/dataLoading/DataLoading';
import ItemNotFound from 'components/ui/itemNotFound/ItemNotFound';
import { getLocalGameCenterData, removeLocalGameCenterPlayer } from 'services/LocalStorageService';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { usePlayerStore } from 'store/PlayerStore';
import { getInitialPlayer } from 'utils/get-initial-player';
import { createGameCenterPlayerAction, getGameCenterPlayerWithIdentifierAction } from 'actions/GameCenterPlayerActions';
import { GameDraftDrawer } from 'components/game-draft-drawer/GameDraftDrawer';
import setAxiosConfig from 'services/AxiosConfig';
import { useIngoStore } from 'store/IngoStore';
import { PlayerErrorType } from 'enums/PlayerErrorType';
import { IPlayerGameCenterDetail } from 'interfaces/PlayerGameCenterDetail';
import { supportBackwardCompatibilityForGamificationLock } from 'utils/helper';
// import { DEFAULT_GC_WIDTH } from 'common/constants';
import { useDimension } from 'hooks/useDimension';
import HttpStatus from 'enums/HttpStatus';
import { DeletedGameCenterMessage } from 'components/deleted-game-center-message/DeletedGameCenterMessage';
import { useSettingPreviewListener } from 'hooks/use-setting-preview-listener';
import { useActiveSettingPreviewStore } from 'store/ActiveSettingPreviewStore';
import { GameCenterSettingsPreview } from 'components/game-center-settings-preview/GameCenterSettingsPreview';
import { loadGameCenter } from '../services/GameCenterService';

const GameCenterPage = () => {
  const { gameCenterId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const [isError, setIsError] = useState(false);
  const [isDeletedGameCenter, setIsDeletedGameCenter] = useState(false);
  const [refetchOnPlayerSet, setRefetchOnPlayerSet] = useState(false);
  const [isNewUuid, setIsNewUuid] = useState(false);

  useSettingPreviewListener();
  const { selectedPreview } = useActiveSettingPreviewStore();

  const { width } = useDimension();
  // const [viewWidth, setViewWidth] = useState<number>(
  //   window.innerWidth > DEFAULT_GC_WIDTH ? DEFAULT_GC_WIDTH : window.innerWidth,
  // );

  const urlParameterUUID = searchParams.get('uuid') as string;
  const urlParameterToken = searchParams.get('token') as string;

  const setToken = useIngoStore((store) => store.setToken);

  useEffect(() => {
    const gameCenterLocalData = getLocalGameCenterData(gameCenterId);
    if (gameCenterLocalData?.uuid && gameCenterLocalData?.uuid !== urlParameterUUID) {
      removeLocalGameCenterPlayer(gameCenterId);
      setIsNewUuid(true);
    }
  }, [urlParameterUUID]);

  useEffect(() => {
    setToken(urlParameterToken);
  }, [urlParameterToken]);

  const [
    updateDetail,
    updateEmail,
    updatePopup,
    updateRewardSettings,
    updateUserBase,
    setModules,
    detail,
    modules,
    userBase,
    updateModuleLock,
  ] = useGameCenterSettingStore(
    (state) => [
      state.updateDetail,
      state.updateEmail,
      state.updatePopup,
      state.updateRewardSettings,
      state.updateUserBase,
      state.setModules,
      state.detail,
      state.modules,
      state.userBase,
      state.updateModuleLock,
    ],
    shallow,
  );
  const [gameCenterPlayer, setGameCenterPlayer, removeGameCenterPlayer, setPlayerCoins, setIsPlayerError] =
    usePlayerStore(
      (state) => [
        state.gameCenterPlayer,
        state.setGameCenterPlayer,
        state.removeGameCenterPlayer,
        state.setPlayerCoins,
        state.setIsPlayerError,
      ],
      shallow,
    );

  useEffect(() => {
    if (!userBase) {
      return;
    }
    if (detail?.customizationKey === CustomizationKeyType.INGO && !urlParameterToken) {
      setIsPlayerError(PlayerErrorType.URL_ERROR);
      return;
    }
    if (
      userBase?.isEnable &&
      userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER &&
      detail?.customizationKey !== CustomizationKeyType.INGO &&
      !urlParameterUUID
    ) {
      setIsPlayerError(PlayerErrorType.URL_ERROR);
      return;
    }

    const initialGameCenterPlayer = {
      gameCenterId,
      organizationId: detail?.organizationId || '',
      brandId: detail?.brandId || '',
      playerId: uuid.v4(),
      coins: 0,
      isVerified: false,
      ...getInitialPlayer(),
    } as IGameCenterPlayer;

    if (!userBase?.isEnable && !gameCenterPlayer && detail) {
      setGameCenterPlayer(initialGameCenterPlayer);
    }
    if (
      userBase.isEnable &&
      userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER &&
      ((urlParameterUUID && gameCenterPlayer?.uuid !== urlParameterUUID) || urlParameterToken)
    ) {
      removeGameCenterPlayer(gameCenterId);
      initialGameCenterPlayer.uuid =
        detail?.customizationKey === CustomizationKeyType.INGO ? urlParameterToken : urlParameterUUID;
      setGameCenterPlayer(initialGameCenterPlayer);
    }
  }, [userBase, detail]);

  const createNonUserBasePlayer = async (payload: IGameCenterPlayer) => {
    const result = await createGameCenterPlayerAction({
      gameCenterId,
      playerId: payload.playerId,
      identifierValue: payload.playerId,
      verifyEmail: false,
      player: getInitialPlayer(),
    });
    if (result.data) {
      setGameCenterPlayer(result.data);
      setRefetchOnPlayerSet(true);
    }
  };

  const getGameCenterPlayerRemoteData = async (gameCenter: IGameCenter, localPlayer: IGameCenterPlayer) => {
    let identifierValue = localPlayer.playerId;
    if (gameCenter.userBase.isEnable) {
      if (gameCenter.userBase.identifierType === UserBaseIdentifierType.USER_INPUT) {
        identifierValue = localPlayer.email;
      } else if (gameCenter.userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        identifierValue = localPlayer.uuid;
      }
    }
    if (detail?.customizationKey === CustomizationKeyType.INGO) {
      identifierValue = urlParameterToken;
    }
    const playerData = await getGameCenterPlayerWithIdentifierAction(
      gameCenterId,
      identifierValue,
      localPlayer.playerId,
    );
    if (playerData.data) {
      setPlayerCoins(playerData.data.coins || 0);
      setGameCenterPlayer(playerData.data);

      if (isNewUuid) {
        setRefetchOnPlayerSet(true);
        setIsNewUuid(false);
      }
    }
  };

  const getGameCenterPlayer = (gameCenter: IGameCenter): void => {
    const localData = getLocalGameCenterData(gameCenterId);
    if (localData?.gameCenterId && localData?.PK && localData?.playerId) {
      getGameCenterPlayerRemoteData(gameCenter, localData);
      setGameCenterPlayer(localData);
      return;
    }
    const initialGameCenterPlayer = {
      gameCenterId,
      organizationId: gameCenter.detail.organizationId || '',
      brandId: gameCenter.detail.brandId || '',
      playerId: uuid.v4(),
      coins: 0,
      isVerified: false,
      ...getInitialPlayer(),
    } as IGameCenterPlayer;
    if (!gameCenter.userBase?.isEnable) {
      // Creating Player if userBase is disabled
      createNonUserBasePlayer(initialGameCenterPlayer);
    } else {
      if (gameCenter.userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        const identifier = gameCenter.userBase.parameters.find((data) => data.isIdentifier);
        if (identifier) {
          const urlParams = new URLSearchParams(window.location.search);
          initialGameCenterPlayer.uuid = (urlParams.get(identifier.type.toLowerCase()) as string) || '';
        }
      }
      setGameCenterPlayer(initialGameCenterPlayer);
    }
  };

  const { isLoading, data, refetch } = useQuery(
    'get-player-game-center',
    () => loadGameCenter(gameCenterId, userBase?.isEnable ? userBase?.identifierType : undefined),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      retry: false,
      onSuccess(playerGameCenterDetail: IPlayerGameCenterDetail) {
        // TODO: we will remove supportBackwardCompatibilityForGamificationLock
        const { gameCenter, moduleLock } = supportBackwardCompatibilityForGamificationLock(
          playerGameCenterDetail as IGameCenter & IPlayerGameCenterDetail,
        );
        updateDetail(gameCenter.detail);
        updateEmail(gameCenter.email);
        updatePopup(gameCenter.popup);
        updateRewardSettings(gameCenter.rewardSettings);
        updateUserBase(gameCenter.userBase);
        setModules(gameCenter.modules);
        getGameCenterPlayer(gameCenter);
        updateModuleLock(moduleLock);
      },
      onError(error: any) {
        if (error.response && error.response.status === 403) {
          setIsPlayerError(PlayerErrorType.TOKEN_ERROR);
        } else if (error.response && error.response.status === HttpStatus.Deleted) {
          setIsDeletedGameCenter(true);
        } else {
          setIsError(true);
        }
      },
    },
  );

  // useEffect(() => {
  //   // const updateDimension = () => {
  //   //   setViewWidth(window.innerWidth > DEFAULT_GC_WIDTH ? DEFAULT_GC_WIDTH : window.innerWidth);
  //   // };
  //   window.addEventListener('resize', updateDimension);

  //   return () => {
  //     window.removeEventListener('resize', updateDimension);
  //   };
  // }, []);

  useEffect(() => {
    refetch();
  }, [gameCenterId]);

  useEffect(() => {
    if (refetchOnPlayerSet) {
      refetch();
      setRefetchOnPlayerSet(false);
    }
  }, [refetchOnPlayerSet]);

  useEffect(() => {
    if (detail?.customizationKey === CustomizationKeyType.INGO && urlParameterToken) {
      setAxiosConfig(CustomizationKeyType.INGO, urlParameterToken);
      refetch();
    }
  }, [detail?.customizationKey]);

  if (!gameCenterId) {
    return <ItemNotFound />;
  }

  if (isLoading) return <DataLoading />;
  if (isDeletedGameCenter) return <DeletedGameCenterMessage />;
  if (isError) return <ItemNotFound />;
  if (!detail) return <div />;

  return (
    <ThemeProvider theme={{ gameCenter: data?.gameCenter as IGameCenter, width: width as any }}>
      <PageWrapper>
        {detail.status === GameCenterStatusType.DRAFT && <GameDraftDrawer />}
        <Header {...detail} />
        <ModuleList modules={modules} isWalletActive={detail?.isWalletActive || false} />
        {userBase?.isEnable && <StickyUserAccess />}
        {Boolean(selectedPreview) && <GameCenterSettingsPreview />}
      </PageWrapper>
    </ThemeProvider>
  );
};

export default GameCenterPage;
