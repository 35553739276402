/* eslint-disable react/no-danger */
import DOMPurify from 'dompurify';

interface Props {
  htmlText: string;
}

export const RenderHtmlText: React.FC<Props> = ({ htmlText }: { htmlText: string }): JSX.Element => {
  // eslint-disable-next-line require-unicode-regexp
  const sanitizedHTML = htmlText.replace(/^\s+|\s+$/g, '');
  return (
    <div
      className="html-text-wrapper"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(sanitizedHTML, { ADD_ATTR: ['target'] }) }}
    />
  );
};
