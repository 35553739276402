export const FailedIcon = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path
          d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z"
          fill="white"
        />
        <path
          d="M40.4996 77.5753C61.2521 77.5753 78.0753 60.7521 78.0753 39.9996C78.0753 19.2471 61.2521 2.42383 40.4996 2.42383C19.7471 2.42383 2.92383 19.2471 2.92383 39.9996C2.92383 60.7521 19.7471 77.5753 40.4996 77.5753Z"
          stroke="url(#paint0_linear_3916_7563)"
          strokeWidth="4.84848"
        />
      </g>
      <path
        d="M43.3789 39.5001L52.158 30.7413C52.5425 30.3568 52.7585 29.8354 52.7585 29.2917C52.7585 28.748 52.5425 28.2266 52.158 27.8421C51.7736 27.4577 51.2521 27.2417 50.7085 27.2417C50.1648 27.2417 49.6433 27.4577 49.2589 27.8421L40.5001 36.6213L31.7414 27.8421C31.3569 27.4577 30.8355 27.2417 30.2918 27.2417C29.7481 27.2417 29.2267 27.4577 28.8422 27.8421C28.4577 28.2266 28.2418 28.748 28.2418 29.2917C28.2418 29.8354 28.4577 30.3568 28.8422 30.7413L37.6214 39.5001L28.8422 48.2588C28.6508 48.4486 28.499 48.6744 28.3953 48.9232C28.2916 49.172 28.2383 49.4389 28.2383 49.7084C28.2383 49.9779 28.2916 50.2448 28.3953 50.4936C28.499 50.7424 28.6508 50.9682 28.8422 51.158C29.032 51.3493 29.2578 51.5012 29.5066 51.6049C29.7554 51.7085 30.0223 51.7619 30.2918 51.7619C30.5613 51.7619 30.8282 51.7085 31.077 51.6049C31.3258 51.5012 31.5516 51.3493 31.7414 51.158L40.5001 42.3788L49.2589 51.158C49.4487 51.3493 49.6745 51.5012 49.9233 51.6049C50.1721 51.7085 50.4389 51.7619 50.7085 51.7619C50.978 51.7619 51.2448 51.7085 51.4936 51.6049C51.7424 51.5012 51.9682 51.3493 52.158 51.158C52.3494 50.9682 52.5013 50.7424 52.6049 50.4936C52.7086 50.2448 52.762 49.9779 52.762 49.7084C52.762 49.4389 52.7086 49.172 52.6049 48.9232C52.5013 48.6744 52.3494 48.4486 52.158 48.2588L43.3789 39.5001Z"
        fill="url(#paint1_linear_3916_7563)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3916_7563"
          x1="40.4996"
          y1="2.42383"
          x2="40.4996"
          y2="77.5753"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D64650" />
          <stop offset="1" stopColor="#8A202A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3916_7563"
          x1="40.5001"
          y1="27.2417"
          x2="40.5001"
          y2="51.7619"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D64650" />
          <stop offset="1" stopColor="#8A202A" />
        </linearGradient>
      </defs>
    </svg>
  );
};
