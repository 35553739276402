import { CustomizationKeyType } from '@flarie/common';
import axios from 'axios';

const setAxiosConfig = (customizationKey: CustomizationKeyType, ingoToken: string) => {
  /* eslint-disable dot-notation */
  // axios.defaults.headers.common['Authorization'] = token;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.defaults.headers.common['CUSTOMIZATION-KEY'] = customizationKey;
  if (ingoToken) {
    ingoToken = ingoToken.replace(/ /gu, '+'); // replace spaces with '+'
    axios.defaults.headers.common['INGO-TOKEN'] = ingoToken;
  }
};
export default setAxiosConfig;
