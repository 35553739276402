import { IGameDimension } from 'interfaces/GameDimension';

const GAME_BASE_DIMENSIONS = {
  width: 750,
  height: 1334,
};

export const getOverlayContainerDimensions = (windowWidth: number, windowHeight: number): IGameDimension => {
  const gameViewRatio = GAME_BASE_DIMENSIONS.width / GAME_BASE_DIMENSIONS.height;
  const windowRatio = windowWidth / windowHeight;

  let width: number;
  let height: number;

  if (gameViewRatio < windowRatio) {
    width = windowHeight * gameViewRatio;
    height = windowHeight;
  } else if (gameViewRatio > windowRatio) {
    width = windowWidth;
    height = windowWidth / gameViewRatio;
  } else {
    width = windowWidth;
    height = windowHeight;
  }

  return { width, height };
};
