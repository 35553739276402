import { IGameCenterAdModule, Size } from '@flarie/common';
import { usePopulateUrlParams } from 'hooks/use-populate-url-params.ts';
import { replaceUrlParameters } from 'utils/replace-url-params';

interface Props extends Partial<IGameCenterAdModule> {}

const AdSection = ({ externalUrlLink = '', adImage, adSize }: Props) => {
  const urlsParams = usePopulateUrlParams();
  return (
    <section className={`pb-3 ${adSize === Size.HALF ? `` : 'col-span-2'}`}>
      {externalUrlLink ? (
        <a href={replaceUrlParameters(externalUrlLink, urlsParams)} target="_blank" rel="noreferrer">
          <img src={adImage} alt={adImage} />
        </a>
      ) : (
        <img src={adImage} alt={adImage} />
      )}
    </section>
  );
};
export default AdSection;
