import { useState, useEffect } from 'react';

const GAME_BASE_DIMENSIONS = {
  width: 750,
  height: 1334,
};

export const useDimension = () => {

  const calculateDimensions = () => {
    const gameViewRatio = GAME_BASE_DIMENSIONS.width / GAME_BASE_DIMENSIONS.height;
    const windowRatio = window.innerWidth / window.innerHeight;

    let width: number;
    let height: number;

    if (gameViewRatio < windowRatio) {
      width = window.innerHeight * gameViewRatio;
      height = window.innerHeight;
    } else if (gameViewRatio > windowRatio) {
      width = window.innerWidth;
      height = window.innerWidth / gameViewRatio;
    } else {
      width = window.innerWidth;
      height = window.innerHeight;
    }

    return { width, height };
  };

  const [dimensions, setDimensions] = useState(calculateDimensions);

  useEffect(() => {
    const handleResize = () => {
      // On window resize, update dimensions
      setDimensions(calculateDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return dimensions;
};