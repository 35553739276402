import { useMemo, useRef, useState, useEffect } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, MenuRef } from 'rc-menu';
import { countries, Country as ICountryBase } from 'countries-list';
import 'rc-dropdown/assets/index.css';
import orderBy from 'lodash/orderBy';

// United States, United Kingdom, Sweden, Denmark, Finland, Norway
const PRIORITY_COUNTRY_KEYS = ['US', 'GB', 'SE', 'DK', 'FI', 'NO'];
const DEFAULT_COUNTRY = 'SE';

export interface ICountry extends ICountryBase {
  key: string;
}

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: any;
}

const getCountryCode = (country: string, defaultCountry: string): ICountry => {
  if (countries[country]) return countries[country];
  const countryObj = Object.keys(countries)
    .map((key: any) => ({ key, ...countries[key] }))
    .find(({ name }: ICountry) => name === country);

  return countryObj || countries[defaultCountry];
};

const CountryInput = ({ placeholder, value, onChange, style }: Props): JSX.Element => {
  const [selectedCountry, setSelectedCountry] = useState<ICountry>(getCountryCode(value, DEFAULT_COUNTRY));
  const dropdownMenuRef = useRef<MenuRef>(null);

  useEffect(() => {
    if (!value && selectedCountry) {
      onChange(DEFAULT_COUNTRY);
    }
  }, []);

  const onSelect = ({ key }: { key: string }): void => {
    setSelectedCountry(countries[key]);
    onChange(key);
  };

  const countriesList = useMemo(() => {
    const countriesSorted = orderBy(
      Object.keys(countries).map((key: any) => ({ key, ...countries[key] })),
      ['name'],
      ['asc'],
    ).filter((item) => !PRIORITY_COUNTRY_KEYS.includes(item.key));

    const priorityCountries = PRIORITY_COUNTRY_KEYS.map((key: string) => ({ key, ...countries[key] }));
    return [...priorityCountries, ...countriesSorted];
  }, [countries]);

  const menu = (
    <Menu onSelect={onSelect} ref={dropdownMenuRef} style={{ width: 'inherit', height: 200, overflow: 'auto' }}>
      {countriesList.map(({ key, name, emoji }: any) => (
        <MenuItem key={key} className="flex h-[40px] cursor-pointer items-center justify-start text-[18px]">
          <span className="mx-2">{emoji}</span>
          <span>{name}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} overlayStyle={{ overflowX: 'hidden' }} animation="slide-up">
      <div
        className="width-full flex h-[56px] items-center justify-start border-0 border-b-2 border-b-black px-3 text-lg outline-none duration-300 ease-in-out focus:border-b-primary focus:outline-none"
        style={style}
      >
        <span>{selectedCountry?.emoji}</span>
        <div className="ml-2 text-[18px]">{selectedCountry?.name}</div>
        <span className="dropdown-caret" />
      </div>
    </Dropdown>
  );
};

export default CountryInput;
