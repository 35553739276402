import { shallow } from 'zustand/shallow';
import { IGameCenterPopUp } from '@flarie/common';
import { Button } from 'components/common/button/Button';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';

interface Props {
  onCloseModal: () => void;
}

export const PlayerAuthComplete = ({ onCloseModal }: Props) => {
  const [userBase, popup] = useGameCenterSettingStore((state) => [state.userBase, state.popup], shallow);

  return (
    <>
      <SignInModal.Title>{userBase?.signUpVerificationText}</SignInModal.Title>
      <div className="flex h-24" />
      <PlayerAuthComplete.CloseButton popup={popup as IGameCenterPopUp} onClick={onCloseModal} />
    </>
  );
};

PlayerAuthComplete.CloseButton = ({ popup, onClick }: { popup: IGameCenterPopUp; onClick: () => void }) => {
  const buttonStyles = {
    backgroundColor: `#${popup?.closeButtonStyle?.backgroundColor}`,
    color: `#${popup?.closeButtonStyle?.color}`,
    fontSize: popup?.closeButtonStyle?.fontSize,
    borderRadius: popup?.closeButtonStyle?.borderRadius,
    fontFamily: 'inherit',
    width: '100%'
  };
  return (
    <Button onClick={onClick} style={buttonStyles}>
      {popup?.closeButtonText || 'Close'}
    </Button>
  );
};
