import { IStyle } from '@flarie/common';
import { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  pageStyle: Partial<IStyle>;
  className?: string;
}

export const PageContainer: FC<Props> = ({ children, pageStyle, className = '' }) => {
  return (
    <div
      className={`relative ml-auto mr-auto h-[100vh] w-full overflow-auto shadow-md ${className}`}
      style={pageStyle as DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>}
    >
      {children}
    </div>
  );
};
