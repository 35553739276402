import { useRef } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, MenuRef } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { IOptionsArray } from 'interfaces/OptionArray';

interface Props {
  placeholder: string;
  value: string | number;
  onChange: (value: string) => void;
  dropdownOptions: IOptionsArray[];
}

export const DropdownInput = ({ dropdownOptions, placeholder, value, onChange }: Props): JSX.Element => {
  const dropdownMenuRef = useRef<MenuRef>(null);

  const onSelect = ({ key }: { key: string }): void => {
    onChange(key);
  };

  const menu = (
    <Menu onSelect={onSelect} ref={dropdownMenuRef} style={{ width: 'inherit', maxHeight: 200, overflow: 'auto' }}>
      {[...dropdownOptions].map(({ name, type }: any) => (
        <MenuItem key={type} className="flex h-[40px] cursor-pointer items-center justify-start text-base">
          <span className="px-6">{name}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} overlayStyle={{ overflowX: 'hidden' }} animation="slide-up">
      <div className="width-full flex h-[56px] items-center justify-start border-0 border-b-2 border-b-black px-3 text-lg outline-none duration-300 ease-in-out focus:border-b-primary focus:outline-none">
        <div className="text-[18px]">
          {value ? dropdownOptions.find(({ type }) => type === value)?.name : placeholder}
        </div>
        <span className="dropdown-caret" />
      </div>
    </Dropdown>
  );
};
