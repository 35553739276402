import dayjs from 'dayjs';
import useGameCenterData from 'hooks/useGameCenterData';
import { FC, useEffect } from 'react';
import Countdown from 'react-countdown';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { getLockTextStyle } from './styles';

interface RendererProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const renderer: FC<RendererProps> = ({ days, hours, minutes, completed }) => {
  if (completed) {
    return <span></span>;
  }
  return (
    <span className="text-base font-semibold">
      {days}D {hours}H {minutes}M
    </span>
  );
};

interface Props {
  days: number;
  createDate: string;
}

export const CountDownTimer: FC<Props> = ({ days, createDate }) => {
  const addedDate = dayjs(createDate).add(days, 'day');
  const differ = addedDate.diff(dayjs(), 'day');
  const openingDate = addedDate.subtract(differ, 'second');
  const openingTimeStamp = openingDate.valueOf();
  const [detail, userBase] = useGameCenterSettingStore((state) => [state.detail, state.userBase]);
  const { refetchGameCenter } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );
  const styles = getLockTextStyle(detail?.lockTextColor || '');
  const onComplete = () => {
    refetchGameCenter();
  };
  useEffect(()=>{
    refetchGameCenter();
  },[])
  return (
    <div style={styles.lockTextStyle}>
      <Countdown date={openingTimeStamp} renderer={renderer} onComplete={onComplete} />
    </div>
  );
};
