import { UserBaseIdentifierType } from '@flarie/common';
import { getLocalGameCenterData } from './LocalStorageService';
import { IGameCenterDto } from '../interfaces/GameCenterDto';
import { getPlayerGameCenter } from '../actions/Actions';

export const loadGameCenter = async (gameCenterId: string, userBaseIdentifierType?: UserBaseIdentifierType) => {
  const localData = getLocalGameCenterData(gameCenterId);
  const playerId = localData?.playerId;

  let identifierValue = playerId;

  if (userBaseIdentifierType === UserBaseIdentifierType.URL_PARAMETER) {
    identifierValue = localData?.uuid;
  }

  if (userBaseIdentifierType === UserBaseIdentifierType.USER_INPUT) {
    identifierValue = localData?.email;
  }

  const payload: IGameCenterDto = {
    gameCenterId,
    playerId: playerId || '',
    identifierValue: identifierValue || '', // TODO: we need to improve it
  };
  const result = await getPlayerGameCenter(payload);
  return result.data;
};
