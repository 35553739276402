import { defaultDateFormat } from '@flarie/common';

const dayjs = require('dayjs');
const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

export const getLocalTime = (utcTime: string, timeZone: string): string => {
  const utcTimeString = dayjs.tz(utcTime, 'UTC').toISOString();
  return dayjs(utcTimeString).tz(timeZone).format(defaultDateFormat);
};

export const convertUtcToLocalTime = (utcDateTime: string) => {
  return getLocalTime(utcDateTime, dayjs.tz.guess());
}