import { IGameCenter, UserBaseIdentifierType } from '@flarie/common';
import { useQuery } from 'react-query';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { shallow } from 'zustand/shallow';
import { IPlayerGameCenterDetail } from 'interfaces/PlayerGameCenterDetail';
import { supportBackwardCompatibilityForGamificationLock } from 'utils/helper';
import { loadGameCenter } from 'services/GameCenterService';

const useGameCenterData = (gameCenterId: string, identifierType?: UserBaseIdentifierType) => {
  const [updateDetail, updateEmail, updatePopup, updateRewardSettings, updateUserBase, setModules, updateModuleLock] =
    useGameCenterSettingStore(
      (state) => [
        state.updateDetail,
        state.updateEmail,
        state.updatePopup,
        state.updateRewardSettings,
        state.updateUserBase,
        state.setModules,
        state.updateModuleLock,
      ],
      shallow,
    );

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    'get-player-game-center',
    () => loadGameCenter(gameCenterId, identifierType),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess(playerGameCenterDetail: IPlayerGameCenterDetail) {
        // TODO: we will remove supportBackwardCompatibilityForGamificationLock
        const { gameCenter, moduleLock } = supportBackwardCompatibilityForGamificationLock(
          playerGameCenterDetail as IGameCenter & IPlayerGameCenterDetail,
        );
        updateDetail(gameCenter.detail);
        updateEmail(gameCenter.email);
        updatePopup(gameCenter.popup);
        updateRewardSettings(gameCenter.rewardSettings);
        updateUserBase(gameCenter.userBase);
        setModules(gameCenter.modules);
        updateModuleLock(moduleLock);
      },
    },
  );

  return { gameCenter: data, refetchGameCenter: refetch, isLoading, isFetching, isError };
};

export default useGameCenterData;
