/* eslint-disable consistent-return */
import { useState, useEffect } from 'react';
import { IGameDimension } from 'interfaces/GameDimension';

export const useWindowDimensions = (): IGameDimension => {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = (): IGameDimension => {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;
    return {
      width,
      height,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = (): void => {
        setWindowDimensions(getWindowDimensions());
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return windowDimensions;
};
