import { FailedIcon } from 'components/icons/FailedIcon';
import { SuccessIcon } from 'components/icons/SuccessIcon';

interface Props {
  success: boolean;
}
export const PlayerVerificationPage = ({ success }: Props) => {
  if (success) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0">
        <div className="flex h-[100vh] w-[100vw] flex-col items-center justify-center bg-gradient-to-br from-[#58BFEC] to-[#3C7CDE]">
          <SuccessIcon />
          <h2 className="text-white">Success!</h2>
          <p className="text-white">Your account has now been verified</p>
        </div>
      </div>
    );
  }
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0">
      <div className="flex h-[100vh] w-[100vw] flex-col items-center justify-center bg-gradient-to-br from-white to-white">
        <FailedIcon />
        <h2 className="text-black">Failed!</h2>
        <p className="text-black">Verification failed. Please try again.</p>
      </div>
    </div>
  );
};
