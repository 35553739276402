import { CustomizationKeyType } from '@flarie/common';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from 'routes/AppRoutes';
import setAxiosConfig from 'services/AxiosConfig';

const queryClient = new QueryClient();

const App = () => {
  setAxiosConfig(CustomizationKeyType.Null, '');
  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
    </QueryClientProvider>
  );
};

export default App;
