import { IStyle } from '@flarie/common';

interface IStyleObject {
  headerFontStyle: Partial<IStyle>;
  descriptionStyle?: Partial<IStyle>;
}

export const getSectionStyle = ({ headerFontStyle, descriptionStyle }: IStyleObject) => {
  return {
    headerFontStyle: {
      color: `#${headerFontStyle.color}`,
      fontSize: headerFontStyle.fontSize,
      textAlign: headerFontStyle.textAlign,
      fontFamily: 'inherit',
      fontWeight: 'bold',
    },
    descriptionStyle: {
      color: `#${descriptionStyle?.color}`,
      fontSize: descriptionStyle?.fontSize,
      textAlign: descriptionStyle?.textAlign,
      fontFamily: 'inherit',
      fontWeight: 'normal',
    },
  };
};
