import { IGameCenterPlayer } from '@flarie/common';
import axios, { AxiosResponse } from 'axios';
import { ApiServicePath } from 'constants/api-service-path';
import { WebHookQueryType } from 'enums/WebHookQueryType';
import { ICouponRedeemDto } from 'interfaces/CouponRedeemDto';
import { IGameCenterPlayerDto } from 'interfaces/GameCenterPlayerDto';
import { IPlayerVerificationDto } from 'interfaces/PlayerVerificationDto';
import { IResendVerificationDto } from 'interfaces/ResendVerificationDto';
import { removeEmptyStringProperties } from 'utils/helper';

export const createGameCenterPlayerAction = async (
  payload: IGameCenterPlayerDto,
  webHookQueryParam: WebHookQueryType = WebHookQueryType.DEFAULT,
): Promise<AxiosResponse<IGameCenterPlayer>> => {
  return axios.post(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYERS}${webHookQueryParam}`,
    payload,
  );
};

export const getGameCenterPlayerWithIdentifierAction = async (
  gameCenterId: string,
  identifierValue: string,
  playerId: string = '',
): Promise<AxiosResponse<IGameCenterPlayer>> => {
  const playerIdQuery = playerId ? `?playerId=${playerId}` : ``;
  return axios.get(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYERS}/game-center/${gameCenterId}/identifier/${encodeURIComponent(identifierValue)}${playerIdQuery}`,
  );
};

export const updateGameCenterPlayerAction = async (
  gameCenterId: string,
  playerId: string,
  identifierValue: string,
  payload: Partial<IGameCenterPlayer>,
  webHookQueryParam: WebHookQueryType = WebHookQueryType.DEFAULT,
): Promise<AxiosResponse<IGameCenterPlayer>> => {
  // remove any property comes with empty string
  const filteredPayload = removeEmptyStringProperties(payload);
  return axios.patch(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYERS}/game-center/${gameCenterId}/player/${playerId}/identifier/${encodeURIComponent(identifierValue)}${webHookQueryParam}`,
    filteredPayload,
  );
};

export const playerVerificationAction = async (payload: IPlayerVerificationDto): Promise<AxiosResponse<string>> => {
  return axios.post(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYER_VERIFICATION}/verify`,
    payload,
  );
};

export const sendVerificationCodeAction = async (payload: IResendVerificationDto): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYER_VERIFICATION}/send`,
    payload,
  );
};

export const redeemsCoupon = async (payload: ICouponRedeemDto): Promise<AxiosResponse<void>> => {
  return axios.post(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_COUPON_REDEEMS}`,
    payload,
  );
};

export const checkPlayerIdentifierAction = async (gameCenterId: string, identifierValue: string): Promise<AxiosResponse<{ playerHashId: string }>> => {
  return axios.get(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYERS}/check-player-identifier/game-center/${gameCenterId}/identifier/${identifierValue}`,
  );
};
