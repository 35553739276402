import { GameCenterStatusType, IGameCenterDetail } from '@flarie/common';
import dayjs from 'dayjs';
import { getLocalTime } from 'utils/get-local-time';

interface IReturnObj {
  notAvailable: boolean;
  expired: boolean;
}

export const isGCValidOrExpired = (detail: IGameCenterDetail): IReturnObj => {
  const { startDate, endDate, timeZone, status } = detail;

  const defaultObj: IReturnObj = {
    notAvailable: false,
    expired: false,
  };

  if (!timeZone) {
    return defaultObj;
  }

  const currentTimeStamp = Date.now();
  const startTimeStamp: any = startDate && dayjs(getLocalTime(startDate, timeZone)).valueOf();
  const endTimeStamp: any = endDate && dayjs(getLocalTime(endDate, timeZone)).valueOf();
  if ((endTimeStamp && endTimeStamp < currentTimeStamp) || status === GameCenterStatusType.EXPIRED) {
    defaultObj.expired = true;
  } else if (startTimeStamp && startTimeStamp > currentTimeStamp) {
    defaultObj.notAvailable = true;
  }
  return defaultObj;
};
