import styled from 'styled-components';

export const DrawerStyled = styled.div<{ isCollapsed: boolean }>`
  position: absolute;
  background-color: white;
  padding: 0.6vh;
  top: 0;
  left: 0;
  right: 0;
  height: 15vh;
  display: flex;
  flex: 1;
  z-index: 1000;
  box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.15);
  transform: ${(props): any => (props.isCollapsed ? `translateY(-97%)` : '')};
  transition: 300ms ease-in-out;
`;

export const ImageContainerStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  backdrop-filter: red;
  padding: 1.5vh 0;
  background-image: url('/drawer-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const TextContainerStyled = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 1.9vh;
  display: flex;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  color: white;
`;

export const DrawerCollapseStyled = styled.div<{ isCollapsed: boolean }>`
  width: 8vh;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vh;
  transform: translateY(80%);
  border-bottom-right-radius: 1vh;
  border-bottom-left-radius: 1vh;
  box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.15);

  svg {
    transition: 300ms ease-in-out;
    transform: ${(props): any => (props.isCollapsed ? 'rotate(180deg)' : '')};
  }
`;

export const LearnMoreButtonStyled = styled.button`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  font-weight: 600;
  font-size: 1.8vh;
  width: 14.5vh;
  height: 4.4vh;
  border-radius: 4vh;
  display: block;
  border: 0;
  margin-bottom: 1vh;
  color: #1e4377;

  &&& {
    background-color: #ffffff;
  }
`;