import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  pageTitle?: string;
  backgroundImg?: string;
  backgroundColor?: string;
  fontFamily: string;
}

export const PageHeader: React.FC<Props> = ({
  fontFamily,
  pageTitle = 'GAME CENTER',
  backgroundImg,
  backgroundColor,
}) => {
  return (
    <Helmet>
      <meta charSet="utf8" />
      <title>{pageTitle}</title>
      <style type="text/css">{`
        body {
          font-family: '${fontFamily}', bebas;
          background: ${backgroundImg ? `url(${backgroundImg}) no-repeat bottom center fixed` : `#${backgroundColor}`};
          background-size: cover;
        }
      `}</style>
    </Helmet>
  );
};
