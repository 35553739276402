import { IGameCenter, IGameTerm, LanguageCode } from '@flarie/common';
import axios, { AxiosResponse } from 'axios';
import { ApiServicePath } from 'constants/api-service-path';
import { IGameCenterDto } from 'interfaces/GameCenterDto';
import { IPlayerGameCenterDetail } from 'interfaces/PlayerGameCenterDetail';

export const getPlayerGameCenter = async (
  gameCenter: IGameCenterDto,
): Promise<AxiosResponse<IPlayerGameCenterDetail>> => {
  const { gameCenterId, playerId, identifierValue } = gameCenter;
  return axios.get<IPlayerGameCenterDetail>(
    `${process.env.REACT_APP_GAME_CENTER_SERVICE_API}/${ApiServicePath.GAME_CENTER_PLAYERS}/${gameCenterId}/game-center`,
    {
      params: {
        playerId,
        identifierValue,
      },
    },
  );
};

export const getGameTerms = (languageCode: LanguageCode, organizationId: string, brandId: string): Promise<AxiosResponse<IGameTerm>> => {
  return axios.get(
    `${process.env.REACT_APP_ASSET_SERVICE_API}/rest/asset-service/v1/game-terms/${languageCode}/${organizationId}/${brandId}`,
  );
};
