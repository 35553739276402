/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-restricted-syntax */
import {
  ComponentType,
  CustomizationKeyType,
  FormInputType,
  IGameCenterFormInput,
  IGameCenterPopUp,
  IGameCenterUserBase,
  IPlayer,
  UserBaseIdentifierType,
} from '@flarie/common';
import { updateGameCenterPlayerAction } from 'actions/GameCenterPlayerActions';
import { PARAM_FORM_INPUTS } from 'common/constants';
import { Button } from 'components/common/button/Button';
import { Input } from 'components/common/input/Input';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { HTTPRequestType } from 'enums/HTTPRequestType';
import { Fragment, useMemo, useState } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { useIngoStore } from 'store/IngoStore';
import { usePlayerStore } from 'store/PlayerStore';
import { getPropertyKeyFromType } from 'utils/get-property-key-from-type';
import { formatPlayerResponse, getWebHookEventTypeByUserBase } from 'utils/helper';
import { shallow } from 'zustand/shallow';
import { BusinessRegionInput } from './BusinessRegionInput';
import CountryInput from './CountryInput';
import { CustomComponent } from './CustomComponent';
import { GenderInput } from './GenderInput';
import { PhoneInput } from './PhoneInput';

interface Props {
  isAddInfoData?: boolean;
}

export const PlayerParameterForm = ({ isAddInfoData = false }: Props) => {
  const [userBase, detail, popup] = useGameCenterSettingStore(
    (state) => [state.userBase, state.detail, state.popup],
    shallow,
  );
  const [gameCenterPlayer, setGameCenterPlayer] = usePlayerStore(
    (state) => [state.gameCenterPlayer, state.setGameCenterPlayer],
    shallow,
  );

  const ingoToken = useIngoStore((store) => store.token);

  const paramValuePair = useMemo(() => {
    const value = {} as any;
    if (!userBase || !gameCenterPlayer) return value;
    for (const param of userBase.parameters) {
      value[getPropertyKeyFromType(param.type)] = gameCenterPlayer[getPropertyKeyFromType(param.type)] || '';
    }
    return value;
  }, [userBase]);

  const [parameterValues, setParameterValues] = useState(paramValuePair);

  const onValueChange = (type: FormInputType, value: string | number | boolean) => {
    setParameterValues((prevState: any) => ({ ...prevState, [getPropertyKeyFromType(type)]: value }));
  };

  const isNotFillMandatoryCheckbox = (playerValues: Partial<IPlayer>) => {
    for (const item of userBase?.parameters || []) {
      if (
        PARAM_FORM_INPUTS.includes(item.type) &&
        item.componentType === ComponentType.CHECKBOX &&
        item.isMandatory &&
        !playerValues[item.type.toLowerCase()]
      ) {
        return true;
      }
    }
    return false;
  };

  const onSubmit = async () => {
    try {
      let identifierValue =
        userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER
          ? gameCenterPlayer?.uuid
          : gameCenterPlayer?.email;

      if (detail?.customizationKey === CustomizationKeyType.INGO) {
        identifierValue = ingoToken;
      }

      if (userBase?.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
        delete parameterValues.uuid;
      } else if (userBase?.identifierType === UserBaseIdentifierType.USER_INPUT) {
        delete parameterValues.email;
      }

      if (parameterValues.age) {
        parameterValues.age = Number(parameterValues.age);
      }
      if (isNotFillMandatoryCheckbox(parameterValues)) {
        return;
      }
      const webHookType = getWebHookEventTypeByUserBase(
        userBase as IGameCenterUserBase,
        HTTPRequestType.PATCH,
        isAddInfoData,
      );
      const result = await updateGameCenterPlayerAction(
        userBase?.gameCenterId as string,
        gameCenterPlayer?.playerId as string,
        identifierValue as string,
        formatPlayerResponse(parameterValues, userBase?.parameters),
        webHookType,
      );
      if (result.data) {
        setGameCenterPlayer({ ...gameCenterPlayer, ...result.data });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  if (!userBase) {
    return <div />;
  }

  return (
    <>
      <SignInModal.Title className={isAddInfoData ? 'mt-0 pt-0 text-lg' : ''}>
        {userBase.additionalFormText}
      </SignInModal.Title>
      {userBase.parameters.map((param: IGameCenterFormInput) => {
        if (param.componentType === ComponentType.CHECKBOX) return <></>;
        return (
          <Fragment key={param.type}>
            {!(isAddInfoData && gameCenterPlayer && gameCenterPlayer[getPropertyKeyFromType(param.type)]) && (
              <div className="mt-4">
                <PlayerParameterForm.Input
                  {...param}
                  value={parameterValues[getPropertyKeyFromType(param.type)]}
                  onChange={(value) => onValueChange(param.type, value)}
                  inputColor={`#${userBase.loginTextColor}`}
                />
              </div>
            )}
          </Fragment>
        );
      })}
      <PlayerParameterForm.ActionButton
        onSubmit={onSubmit}
        isAddInfoData={isAddInfoData}
        popup={popup as IGameCenterPopUp}
      />
      {userBase.parameters.map((param: IGameCenterFormInput) => {
        if (param.componentType !== ComponentType.CHECKBOX) return <></>;
        return (
          <Fragment key={param.type}>
            {!(isAddInfoData && gameCenterPlayer && gameCenterPlayer[getPropertyKeyFromType(param.type)]) && (
              <div className="mt-4">
                <PlayerParameterForm.Input
                  {...param}
                  value={parameterValues[getPropertyKeyFromType(param.type)]}
                  onChange={(value) => onValueChange(param.type, value)}
                  inputColor={`#${userBase.loginTextColor}`}
                />
              </div>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

PlayerParameterForm.ActionButton = ({
  popup,
  onSubmit,
  isAddInfoData,
}: {
  popup: IGameCenterPopUp;
  onSubmit: () => void;
  isAddInfoData: boolean;
}) => {
  const buttonStyle = {
    backgroundColor: `#${popup?.confirmButtonStyle?.backgroundColor}`,
    color: `#${popup?.confirmButtonStyle?.color}`,
    fontSize: popup?.confirmButtonStyle?.fontSize,
    borderRadius: popup?.confirmButtonStyle?.borderRadius,
    fontFamily: 'inherit',
  };
  return (
    <Button onClick={onSubmit} block className={`${isAddInfoData ? 'mt-8' : 'mt-12'} mb-4`} style={buttonStyle}>
      {popup.confirmButtonText}
    </Button>
  );
};

interface InputProps {
  onChange: (value: string | number | boolean) => void;
  value: string | number;
  inputColor: string;
}

PlayerParameterForm.Input = ({
  isIdentifierType,
  value,
  type,
  placeholder,
  onChange,
  componentType,
  componentValues,
  inputColor,
}: IGameCenterFormInput & InputProps) => {
  const props = {
    style: { color: inputColor, borderBottomColor: inputColor },
    inputColor,
  };
  if (isIdentifierType === UserBaseIdentifierType.URL_PARAMETER) {
    return <div />;
  }
  if (type === FormInputType.COUNTRY) {
    return <CountryInput value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (type === FormInputType.PHONE) {
    return <PhoneInput value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (type === FormInputType.BUSINESS_REGION) {
    return <BusinessRegionInput value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (type === FormInputType.GENDER) {
    return <GenderInput value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (type === FormInputType.AGE) {
    return <Input type="number" value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (type === FormInputType.EMAIL) {
    return <Input type="email" value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
  }
  if (
    [
      FormInputType.OTHER1,
      FormInputType.OTHER2,
      FormInputType.OTHER3,
      FormInputType.OTHER4,
      ...PARAM_FORM_INPUTS,
    ].includes(type)
  ) {
    return (
      <CustomComponent
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        componentType={componentType}
        componentValues={componentValues}
        type={type}
      />
    );
  }
  return <Input value={value as string} onChange={onChange} placeholder={placeholder} {...props} />;
};
