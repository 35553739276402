interface Props {
  children: React.ReactNode;
  onClick: () => void;
  block?: boolean;
  disabled?: boolean;
  className?: string;
  style?: any;
}

export const Button = ({ children, onClick, block = false, disabled = false, className = '', style = {} }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      onKeyDown={onClick}
      className={`rounded-full bg-primary py-4 px-12 text-lg font-bold capitalize text-white duration-300 ease-in-out ${!disabled ? `active:scale-90` : ``
        } disabled:bg-disabled ${block ? 'w-full' : ''} ${className}`}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
};
