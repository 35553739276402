import { IPlayer } from '@flarie/common';

export const getInitialPlayer = (): IPlayer => {
  return {
    uuid: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    company: '',
    phone: '',
    age: 0,
    gender: '',
    birthday: '',
    address: '',
    businessRegion: '',
    country: '',
    city: '',
    zipCode: '',
    src: '',
    other1: '',
    other2: '',
    other3: '',
    other4: '',
    consent: false,
    consentMandatory: false,
  } as IPlayer;
};
