import { defaultGameCenter, IStyle } from '@flarie/common';

interface IStyleObject {
  headerTitleStyle: Partial<IStyle>;
}

export const getHeaderStyle = ({ headerTitleStyle }: IStyleObject, currencyTextColor: string) => {
  return {
    headerTitleStyle: {
      color: `#${headerTitleStyle.color || defaultGameCenter.detail.headerTitleStyle.color}`,
      fontSize: headerTitleStyle.fontSize || defaultGameCenter.detail.headerTitleStyle.fontSize,
      textAlign: headerTitleStyle.textAlign || defaultGameCenter.detail.headerTitleStyle.textAlign,
      fontFamily: 'inherit',
      fontWeight: 'bold',
    },
    headerBorderStyle: {
      borderColor: `#${headerTitleStyle.color}`,
    },
    currencyTextStyle: {
      color: `#${currencyTextColor || defaultGameCenter.detail.currencyTextColor}`,
    },
  };
};
