/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { GameCenterModuleLockType, Size, defaultDateFormat } from '@flarie/common';
import { Modal } from 'components/common/modal/Modal';
import Lock from 'components/lock/Lock';
import ClosePopup from 'components/popup/ClosePopup';
import { CoinIcons } from 'components/ui/CoinIcon';
import dayjs from 'dayjs';
import { IReward } from 'interfaces/Reward';
import { FC, useState } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { usePlayerStore } from 'store/PlayerStore';
import Timezone from 'timezone-enum';
import { convertUtcToLocalTime, getLocalTime } from 'utils/get-local-time';
import { addThousandSeparator } from 'utils/helper';
import { isRewardOutOfStock } from 'utils/reward-helper';
import { shallow } from 'zustand/shallow';
import useGameCenterData from 'hooks/useGameCenterData';
import { RewardDetails } from './RewardDetails';

const Reward: FC<IReward> = (reward) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openLockModal, setOpenLockModal] = useState<boolean>(false);
  const [popup, detail, moduleLock, userBase] = useGameCenterSettingStore((state) => [state.popup, state.detail, state.moduleLock, state.userBase], shallow);
  const gameCenterPlayer = usePlayerStore((state) => state.gameCenterPlayer);

  const foundRewardLock = () => {
    const foundLock = moduleLock.rewards.find((item) => item.id === reward.id && [GameCenterModuleLockType.REWARD_GAMIFICATION, GameCenterModuleLockType.ADVENTURE_GAMIFICATION].includes(item.type));
    return foundLock;
  }
  const { isFetching } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  const getLock = () => {
    if (reward.gameCenterRewardId) return null;
    if (
      reward?.isStartDateCountDown &&
      reward.startDate &&
      dayjs(getLocalTime(reward.startDate, detail?.timeZone as Timezone)) > dayjs()
    ) {
      const startDate = dayjs(getLocalTime(reward.startDate, detail?.timeZone as Timezone));
      const days = startDate.diff(dayjs(), 'day');
      const incrementedStartDate = startDate.subtract(days, 'day').format(defaultDateFormat);
      return (
        <Lock days={days} countDownDate={incrementedStartDate as string} backgroundImageUrl={reward.countDownImage} />
      );
    }
    if (reward?.isRewardLock && gameCenterPlayer?.PK)
      return (
        <Lock
          days={reward?.unLockDays}
          countDownDate={convertUtcToLocalTime(gameCenterPlayer?.createdAt)}
          backgroundImageUrl={reward.rewardLockImage}
        />
      );
    if (reward?.isRewardCoolDown && gameCenterPlayer?.PK && reward?.lastRewardPurchaseTime)
      return (
        <Lock
          days={reward?.coolDownDays}
          countDownDate={convertUtcToLocalTime(reward?.lastRewardPurchaseTime)}
          backgroundImageUrl={reward.coolDownImage}
        />
      );
    const foundLock = foundRewardLock();
    if (foundLock) {
      return <Lock days={foundLock?.unLockDays} />;
    }
    return null;
  };

  const onOpenModal = () => {
    if (isRewardOutOfStock(reward)) {
      setOpenLockModal(true);
      return;
    }
    if (!reward.gameCenterRewardId && (reward?.isRewardLock || reward?.isRewardCoolDown) || foundRewardLock()) {
      setOpenLockModal(true);
    } else {
      setOpenModal(true);
    }
  };

  const onCloseLockModal = () => {
    setOpenLockModal(false);
  };
  const onCloseModal = () => {
    setOpenModal(false);
  };

  const getPopUpText = (): string => {
    if (isRewardOutOfStock(reward)) {
      return popup?.prizeOutOfStockText || '';
    }
    return foundRewardLock()?.message || popup?.coolDownText || '';
  };

  return (
    <div
      className={`mb-4 ${reward?.size === Size.HALF ? '' : 'col-span-2'} ${gameCenterPlayer?.PK && isRewardOutOfStock(reward) && !getLock() ? 'opacity-50' : ''
        }`}
    >
      <div className="relative overflow-hidden" onClick={onOpenModal}>
        <button className="flex h-full w-full items-center" type="button">
          {!isFetching && <img className="ml-auto mr-auto w-full" src={reward?.rewardImage} alt="Reward" />}
        </button>
        {getLock()}
      </div>
      {Boolean(reward?.size === Size.HALF) && !isFetching && (
        <div className="mt-4 text-center">
          <span className="block">
            <button type="button" onClick={onOpenModal}>
              {reward?.name}
            </button>
            <br />
            <div className="flex items-center justify-center font-semibold">
              <CoinIcons size={17} /> <span>&nbsp;{addThousandSeparator(reward?.rewardPrice)}</span>
            </div>
          </span>
        </div>
      )}
      <Modal
        isOpen={openLockModal}
        onClose={onCloseLockModal}
        showFooter={false}
        showBack={false}
        showClose={false}
        contentClassName="justify-center !pt-0 !bg-black/[.70]"
        outerContentClassName="!pt-0"
      >
        <ClosePopup onCloseModal={onCloseLockModal} popupText={getPopUpText() || ''} />
      </Modal>
      <Modal
        isOpen={openModal}
        onClose={onCloseModal}
        showFooter={false}
        showBack={false}
        showClose={false}
        contentClassName="!p-0"
        outerContentClassName="!p-0"
      >
        <RewardDetails reward={reward} onClose={onCloseModal}></RewardDetails>
      </Modal>
    </div>
  );
};
export default Reward;
