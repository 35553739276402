/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CustomizationKeyType, UserBaseIdentifierType } from '@flarie/common';
import { getGameCenterPlayerWithIdentifierAction } from 'actions/GameCenterPlayerActions';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { useEffect, useState } from 'react';
import { acceptLocalTerm, checkIfLocalTermAccepted, checkIfVerifiedPlayer } from 'services/LocalStorageService';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { useIngoStore } from 'store/IngoStore';
import { usePlayerStore } from 'store/PlayerStore';
import { checkKnownPlayer } from 'utils/check-known-player';
import { AddInfoModal } from 'components/add-info-modal/AddInfoModal';
import useGameCenterData from 'hooks/useGameCenterData';
import { useDimension } from 'hooks/useDimension';
import { shallow } from 'zustand/shallow';
import { getSectionStyles } from './styles.module';

export const StickyUserAccess = () => {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const [hideSticky, setHideSticky] = useState<boolean>(false);
  const [userBase, detail] = useGameCenterSettingStore((state) => [state.userBase, state.detail], shallow);
  const [gameCenterPlayer, setGameCenterPlayer] = usePlayerStore(
    (store) => [store.gameCenterPlayer, store.setGameCenterPlayer],
    shallow,
  );
  const setPlayerCoins = usePlayerStore((state) => state.setPlayerCoins);
  const ingoToken = useIngoStore((store) => store.token);
  const { width } = useDimension();

  const [showAddInfoModal, setShowAddInfoModal] = useState<boolean>(false);

  const { refetchGameCenter } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  useEffect(() => {
    if (!gameCenterPlayer || !userBase) return;

    const getGameCenterPlayer = async () => {
      const identifierValue =
        detail?.customizationKey === CustomizationKeyType.INGO ? ingoToken : gameCenterPlayer?.uuid;
      const result = await getGameCenterPlayerWithIdentifierAction(userBase.gameCenterId as string, identifierValue);
      if (result.data) {
        acceptLocalTerm(userBase.gameCenterId as string, gameCenterPlayer.uuid);
        setGameCenterPlayer(result.data);
        setPlayerCoins(result.data.coins || 0);
        setHideSticky(true);

        const isAllParamsFilled = checkKnownPlayer(gameCenterPlayer, userBase.parameters);
        if (!isAllParamsFilled) {
          setShowAddInfoModal(true);
        }
      }
    };

    if (
      !gameCenterPlayer.PK &&
      userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER &&
      gameCenterPlayer.uuid
    ) {
      getGameCenterPlayer();
    } else {
      const isAllParamsFilled = checkKnownPlayer(gameCenterPlayer, userBase.parameters);
      if (userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER && gameCenterPlayer.uuid) {
        const identifierValue =
          detail?.customizationKey === CustomizationKeyType.INGO ? ingoToken : gameCenterPlayer.uuid;

        const isLocalTermAccepted = checkIfLocalTermAccepted(userBase.gameCenterId, identifierValue);
        if (isLocalTermAccepted) {
          setHideSticky(true);
        }
        if (isLocalTermAccepted && !isAllParamsFilled) {
          setShowAddInfoModal(true);
        } else {
          setShowAddInfoModal(false);
        }
      } else if (checkIfVerifiedPlayer(userBase.gameCenterId, gameCenterPlayer.email)) {
        setHideSticky(true);
        if (!isAllParamsFilled) {
          setShowAddInfoModal(true);
        } else {
          setShowAddInfoModal(false);
        }
      }
    }
  }, [gameCenterPlayer]);

  if (!userBase?.isEnable) {
    return <div />;
  }

  const styles = getSectionStyles(width);

  const hideLoginForm = () => {
    setShowLoginForm(false);
    refetchGameCenter();
  };
  useEffect(()=>{
    refetchGameCenter();
  },[])

  return (
    <>
      {!hideSticky && (
        <div
          role="button"
          onClick={() => setShowLoginForm(true)}
          className="absolute top-0 bottom-0 left-0 right-0 bg-transparent"
        >
          <div style={styles.containerStyle}>
            <span style={styles.stickyTextStyle}>{userBase.stickyText}</span>
          </div>
        </div>
      )}
      {showLoginForm && <SignInModal isOpen={showLoginForm} onClose={hideLoginForm} />}
      {showAddInfoModal && hideSticky && !showLoginForm && (
        <AddInfoModal isOpen={showAddInfoModal} onClose={() => setShowAddInfoModal(false)} />
      )}
    </>
  );
};
