/* eslint-disable dot-notation */
import { IGameCenterRewardSettings } from "@flarie/common";

export const getRewardDetailStyle = (rewardSettings: IGameCenterRewardSettings) => {
  const pageBackground = {}
  if(rewardSettings.backgroundImage){
    pageBackground['backgroundImage'] = `url(${rewardSettings.backgroundImage})`
  }else{
    pageBackground['background'] = `#${rewardSettings.backgroundColor}`
  }
  const headerFontStyle ={
    color: `#${rewardSettings?.headerFontStyle?.color}`,
    fontSize: rewardSettings?.headerFontStyle?.fontSize,
    textAlign: rewardSettings?.headerFontStyle?.textAlign,
    fontFamily: 'inherit',
  }
  const descriptionTextStyle ={
    color: `#${rewardSettings?.descriptionTextStyle?.color}`,
    fontSize: rewardSettings?.descriptionTextStyle?.fontSize,
    textAlign: rewardSettings?.descriptionTextStyle?.textAlign,
    fontFamily: 'inherit',
  }
  const buttonStyle ={
    backgroundColor: `#${rewardSettings?.buttonStyle?.backgroundColor}`,
    color: `#${rewardSettings?.buttonStyle?.color}`,
    borderRadius: rewardSettings?.buttonStyle?.borderRadius,
    top: rewardSettings?.buttonStyle?.top,
    fontSize: rewardSettings?.buttonStyle?.fontSize,
    textAlign: rewardSettings?.buttonStyle?.textAlign,
    fontFamily: 'inherit',
  }

  return {pageBackground, headerFontStyle, descriptionTextStyle, buttonStyle}
}