import { CircleLoaderContainerStyled, CircleLoaderStyled } from './styled';

export const CircleLoader = (): JSX.Element => {
  return <CircleLoaderStyled />;
};

CircleLoader.Container = (): JSX.Element => (
  <CircleLoaderContainerStyled>
    <CircleLoader />
  </CircleLoaderContainerStyled>
);
