import { IGameCenterPlayer, UserBaseIdentifierType } from '@flarie/common';
import { getGameCenterPlayerWithIdentifierAction } from 'actions/GameCenterPlayerActions';
import { IGameCenterDto } from 'interfaces/GameCenterDto';
import { useQuery } from 'react-query';
import { usePlayerStore } from 'store/PlayerStore';
import { getPlayerIdIdentifierByGameCenter } from 'utils/helper';

const useGameCenterPlayer = (gameCenterId: string, identifierType?: UserBaseIdentifierType) => {
  const setPlayerCoins = usePlayerStore((state) => state.setPlayerCoins);

  const loadPlayerCoins = async () => {
    const payload: IGameCenterDto = getPlayerIdIdentifierByGameCenter(gameCenterId, identifierType);
    const result = await getGameCenterPlayerWithIdentifierAction(
      payload.gameCenterId,
      payload.identifierValue,
      payload.playerId,
    );
    return result.data;
  };

  const { data, refetch, isLoading, isError } = useQuery('get-player-center', loadPlayerCoins, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess(playerInformation: IGameCenterPlayer) {
      setPlayerCoins(playerInformation?.coins || 0);
    },
  });

  return { gameCenterPlayer: data, refetchGameCenterPlayer: refetch, isLoading, isError };
};

export default useGameCenterPlayer;
