import React from 'react';
import { IGameCenterDetail } from '@flarie/common';
import { CoinIcons } from 'components/ui/CoinIcon';
import { usePlayerStore } from 'store/PlayerStore';
import { addThousandSeparator } from 'utils/helper';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import { getHeaderStyle } from './styles';

interface Props extends IGameCenterDetail { }

export const Header: React.FC<Props> = ({
  logoImage = '',
  headerTitle = '',
  currencyImage,
  headerTitleStyle,
  isWalletActive,
  currencyTextColor,
}) => {
  const styles = getHeaderStyle({ headerTitleStyle }, currencyTextColor);
  const playerCoins = usePlayerStore((state) => state.playerCoins);

  return (
    <div className="mb-4 border-b-2 border-solid" style={styles.headerBorderStyle}>
      <div>
        <img src={logoImage} alt="logo" className="inline-block" />
      </div>
      <div className="flex w-full justify-between pt-5 pb-[6px] px-8">
        <DynamicFontLoader fontId={headerTitleStyle.font as string} fontName="header-title-font">
          <span className="block w-full" style={styles.headerTitleStyle}>
            {headerTitle}
          </span>
        </DynamicFontLoader>
        {Boolean(isWalletActive) && (
          <div className="flex min-w-[70px] justify-end pl-2 my-auto pt-[5px]">
            <CoinIcons size={20} src={currencyImage} />
            <DynamicFontLoader fontId={headerTitleStyle.font as string} fontName="header-font-family">
              <span className={`text-xl leading-none `} style={styles.currencyTextStyle}>
                &nbsp; {addThousandSeparator(playerCoins || 0)}
              </span>
            </DynamicFontLoader>
          </div>
        )}
      </div>
    </div>
  );
};
