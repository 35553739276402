import { ReactNode } from 'react';

interface Props {
  children: ReactNode | string;
}

export const GameCenterExpired = ({ children }: Props) => {
  return (
    <div className="mx-auto flex h-[100vh] w-[65%] items-center justify-center">
      <span className="w-full text-center font-body text-[22px] text-white">{children}</span>
    </div>
  );
};
