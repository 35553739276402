import { GameCenterRewardType, RewardStockType } from "@flarie/common";
import { IReward } from "interfaces/Reward";

export const isValidUniqueCodePurchaseLimit = (reward: IReward): Boolean => {
  return !([GameCenterRewardType.COUPON_CODE_UNIQUE, GameCenterRewardType.URL_UNIQUE].includes(reward.rewardType) &&
  reward.purchaseLimit === reward.totalPurchaseCount)
};
export const isValidCommonCodePurchaseLimit = (reward: IReward): Boolean => {
  return !([GameCenterRewardType.COUPON_CODE_GENERAL, GameCenterRewardType.URL_GENERAL, GameCenterRewardType.FORM, GameCenterRewardType.NONE].includes(
    reward.rewardType,
  ) &&
  reward.stockType === RewardStockType.LIMITED &&
  reward.purchaseLimit === reward.totalPurchaseCount)
};

export const isRewardOutOfStock = (reward: IReward): Boolean => {
  return (!isValidUniqueCodePurchaseLimit(reward) || !isValidCommonCodePurchaseLimit(reward))
};