import { IGameCenterPlayer, ObjectLiteral } from '@flarie/common';

const PLAYED_GAMES_KEY_PREFIX = `PLAYED_GAMES#`;

export const getLocalGameCenterData = (gameCenterId: string): IGameCenterPlayer | null => {
  try {
    const data = window.localStorage.getItem(gameCenterId);
    return data ? JSON.parse(data) : null;
  } catch {
    return null;
  }
};

export const setLocalGameCenterData = (gameCenterId: string, payload: IGameCenterPlayer): void => {
  try {
    window.localStorage.setItem(gameCenterId, JSON.stringify(payload));
  } catch {
    //
  }
};

export const acceptLocalTerm = (gameCenterId: string, uuid: string): void => {
  try {
    window.localStorage.setItem(`${gameCenterId}#${uuid}`, 'true');
  } catch {
    //
  }
};

export const checkIfLocalTermAccepted = (gameCenterId: string, uuid: string): boolean => {
  try {
    const data = window.localStorage.getItem(`${gameCenterId}#${uuid}`);
    return data === 'true';
  } catch {
    return false;
  }
};

export const removeLocalGameCenterPlayer = (gameCenterId: string): void => {
  try {
    window.localStorage.removeItem(gameCenterId);
  } catch {
    //
  }
};

export const addPlayerVerified = (gameCenterId: string, identifierValue: string): void => {
  try {
    window.localStorage.setItem(`${gameCenterId}#${identifierValue}`, 'true');
  } catch {
    //
  }
};

export const checkIfVerifiedPlayer = (gameCenterId: string, identifierValue: string): boolean => {
  try {
    const data = window.localStorage.getItem(`${gameCenterId}#${identifierValue}`);
    return data === 'true';
  } catch {
    return false;
  }
};

export const setPlayedGamesMap = (gameCenterId: string, playedGames: ObjectLiteral): void => {
  try {
    window.localStorage.setItem(`${PLAYED_GAMES_KEY_PREFIX}${gameCenterId}`, JSON.stringify(playedGames));
  } catch {
    //
  }
};

export const getPlayedGamesMap = (gameCenterId: string): ObjectLiteral => {
  try {
    const data = window.localStorage.getItem(`${PLAYED_GAMES_KEY_PREFIX}${gameCenterId}`);
    return data ? JSON.parse(data) : {};
  } catch {
    return {};
  }
};
