export enum GameCenterSettingsPreviewType {
  LANDING_SIGN_IN_UP_PAGE = 'LANDING_SIGN_IN_UP_PAGE',
  ADDITIONAL_FORM = 'ADDITIONAL_FORM',
  SIGN_IN_CODE = 'SIGN_IN_CODE',
  SIGN_UP_VERIFICATION = 'SIGN_UP_VERIFICATION',
  PURCHASE_ITEM = 'PURCHASE_ITEM',
  PURCHASE_COMPLETED = 'PURCHASE_COMPLETED',
  ERROR = 'ERROR',
  COOLDOWN = 'COOLDOWN',
  NOT_ENOUGH_COINS = 'NOT_ENOUGH_COINS',
  PRIZE_OUT_OF_STOCK = 'PRIZE_OUT_OF_STOCK',
  CURRENCY_COOLDOWN = 'CURRENCY_COOLDOWN',
}
