const StaticTerms = (): JSX.Element => (
  <>
    <br />
    <img
      src="https://dwfedcf5lscmq.cloudfront.net/studio/60706eb9d459b.png"
      alt="Flarie logo"
      style={{ width: '20%' }}
    />
    <br />
    The game is hosted and technically provided by Flarie and is free of charge. By playing the game you acknowledge
    that you have read and accept Flarie&apos;s&nbsp;
    <a href="https://www.flarie.com/terms" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">
      Terms & Conditions
    </a>
    ,{' '}
    <a href="https://www.flarie.com/privacy" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a href="https://www.flarie.com/cookies" target="_blank" style={{ textDecoration: 'underline' }} rel="noreferrer">
      Cookie Policy
    </a>
    .
    <br />
    <br />
    The Flarie partner that is the creator of this game is fully responsible for all text, images, communication and any
    other relevant responsibilities connected to the game.
    <br />
    <br />
    For any questions, please send an email to contact@flarie.com.
    <br />
    <hr style={{ width: '90%', marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }} />
  </>
);

export default StaticTerms;
