import { GameCenterSettingsPreviewType } from 'enums/GameCenterSettingsPreviewType';
import { create } from 'zustand';

interface Props {
  selectedPreview: GameCenterSettingsPreviewType | null;
  setSelectedPreview: (selectedPreview: GameCenterSettingsPreviewType | null) => void;
}

export const useActiveSettingPreviewStore = create<Props>((set) => ({
  selectedPreview: null,
  setSelectedPreview: (selectedPreview: GameCenterSettingsPreviewType | null): void => set({ selectedPreview }),
}));
