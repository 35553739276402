import { RadioInputItem } from 'components/common/radio-input-item/RadioInputItem';
import { IOptionsArray } from 'interfaces/OptionArray';

interface Props {
  placeholder: string;
  value: string | number;
  onChange: (value: string) => void;
  radioOptions: IOptionsArray[];
}

export const RadioInput = ({ radioOptions, placeholder, value, onChange }: Props) => {
  return (
    <div className="mx-2">
      <h3 className="m text-[18px]">{placeholder}</h3>
      {radioOptions?.map((item) => (
        <RadioInputItem
          checked={value === item.type}
          label={item.name}
          key={item.type}
          className="mb-6"
          onClick={() => onChange(item.type)}
        />
      ))}
    </div>
  );
};
