/* eslint-disable require-unicode-regexp */
import { ChangeEvent, useMemo, useRef, useState } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem } from 'rc-menu';
import { countries } from 'countries-list';
import { PHONE_PATTERN } from 'common/input-patterns';
import 'rc-dropdown/assets/index.css';

const SUPPORTED_COUNTRIES = ['DK', 'FI', 'FR', 'DE', 'IT', 'NO', 'SE', 'GB'];
const DEFAULT_COUNTRY = 'SE';

const isNumber = (value: string): boolean => {
  return /^\d*$/.test(value);
};

export const getCountryObjectFromValue = (value: string): any => {
  if (!value) {
    return DEFAULT_COUNTRY;
  }
  const selectedCountry = Object.keys(countries).find((key: string) => value.includes(`+${countries[key].phone}`));
  if (!selectedCountry) {
    return DEFAULT_COUNTRY;
  }
  return selectedCountry;
};

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: object;
}

export const PhoneInput = ({ placeholder, value, onChange, style = {} }: Props): JSX.Element => {
  const [selectedCountry, setSelectedCountry] = useState<any>(countries[getCountryObjectFromValue(value)]);

  const onSelect = ({ key }: { key: string }): void => {
    setSelectedCountry(countries[key]);
    onChange(`+${countries[key].phone}`);
  };

  const countriesArray = useMemo(
    () => SUPPORTED_COUNTRIES.map((key: any) => ({ key, ...countries[key] })),
    [SUPPORTED_COUNTRIES],
  );

  const countryCodePrefix = `+${selectedCountry.phone}`;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const inputValue = e.target.value;
    if (inputValue.startsWith(countryCodePrefix) && isNumber(inputValue.slice(countryCodePrefix.length))) {
      onChange(inputValue);
    }
  };

  const handleInputBlur = (): void => {
    if (!value.startsWith(countryCodePrefix)) {
      onChange(countryCodePrefix);
      if (inputRef.current) {
        inputRef.current.value = countryCodePrefix;
      }
    }
  };

  const menu = (
    <Menu onSelect={onSelect}>
      {countriesArray.map(({ key, name, emoji, phone }: any) => (
        <MenuItem key={key} className="flex h-9 items-center">
          <span className="mx-2">{emoji}</span>
          <span className="text-[18px]">{name}</span>
          <span className="ml-2 text-slate-400">+{phone}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <div
      className="width-full flex h-[56px] items-center justify-start border-0 border-b-2 border-b-black px-3 text-lg outline-none duration-300 ease-in-out focus:border-b-primary focus:outline-none"
      style={style}
    >
      <Dropdown trigger={['click']} overlay={menu} animation="slide-up">
        <button type="button" className="mr-2">
          {selectedCountry.emoji}
          <span className="dropdown-caret" />
        </button>
      </Dropdown>
      <input
        type="tel"
        ref={inputRef}
        value={value || `+${selectedCountry.phone}`}
        placeholder={placeholder}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        className="flex h-[56px] w-full flex-1 bg-transparent text-[18px] outline-none"
        // pattern="\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$"
        pattern={PHONE_PATTERN}
      />
    </div>
  );
};
