/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { Button } from 'components/common/button/Button';
import { SignInModal } from 'components/sign-in-modal/SignInModal';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { Input } from 'components/common/input/Input';
import { usePlayerStore } from 'store/PlayerStore';
import { IPlayerVerificationDto } from 'interfaces/PlayerVerificationDto';
import { playerVerificationAction, sendVerificationCodeAction } from 'actions/GameCenterPlayerActions';
import { addPlayerVerified } from 'services/LocalStorageService';
import { IGameCenterPlayer } from '@flarie/common';

interface Props {
  onCloseModal: () => void;
}

export const PlayerVerificationForm = ({ onCloseModal }: Props) => {
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [userBase, popup] = useGameCenterSettingStore((state) => [state.userBase, state.popup], shallow);
  const [gameCenterPlayer, setGameCenterPlayer, playerHashId] = usePlayerStore(
    (state) => [state.gameCenterPlayer, state.setGameCenterPlayer, state.playerHashId],
    shallow,
  );
  const [verificationErrorMsg, setVerificationErrorMsg] = useState<string>('');

  useEffect(() => {
    if (!gameCenterPlayer || !gameCenterPlayer.email) {
      return;
    }
    const handleVerification = async () => {
      await sendVerificationCodeAction({
        playerHashId,
        gameCenterId: gameCenterPlayer.gameCenterId,
        identifierValue: gameCenterPlayer.email,
      });
    };
    handleVerification();
  }, []);

  const onSubmitVerification = async () => {
    setVerificationErrorMsg('');
    if (!verificationCode.length || !gameCenterPlayer) {
      return;
    }
    const payload: IPlayerVerificationDto = {
      playerHashId,
      gameCenterId: gameCenterPlayer.gameCenterId,
      identifierValue: gameCenterPlayer.email,
      verificationCode,
    };
    try {
      const result = await playerVerificationAction(payload);
      if (result.data) {
        const playerData: IGameCenterPlayer = result.data as unknown as IGameCenterPlayer;
        addPlayerVerified(userBase?.gameCenterId as string, gameCenterPlayer.email);
        setGameCenterPlayer({ ...playerData, isVerified: true });
        onCloseModal();
      }
    } catch (error: any) {
      setVerificationErrorMsg('Wrong Verification Code');
    }
  };

  const onRequestResend = async () => {
    if (!gameCenterPlayer) {
      return;
    }
    try {
      await sendVerificationCodeAction({
        playerHashId,
        gameCenterId: gameCenterPlayer.gameCenterId,
        identifierValue: gameCenterPlayer.email,
      });
      // TODO: SHOW TOAST
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  if (!userBase || !gameCenterPlayer) {
    return <div />;
  }

  return (
    <>
      <SignInModal.Title>{userBase.signInCodeText}</SignInModal.Title>
      <div className="bold mt-[2vh] mb-[5vh] text-center text-[3vh] font-bold">{gameCenterPlayer.email}</div>
      <Input
        value={verificationCode}
        onChange={setVerificationCode}
        placeholder="Verification Code"
        style={{
          color: `#${userBase.loginTextColor}`,
          fontSize: '3vh',
          borderBottomColor: `#${userBase.loginTextColor}`,
        }}
        inputColor={`#${userBase.loginTextColor}`}
        error={verificationErrorMsg}
      />
      <div
        role="button"
        className="mt-4 flex select-none self-end underline duration-300 ease-in-out active:scale-90"
        onClick={onRequestResend}
      >
        {/* MAKE THIS VALUE DYNAMIC, ADD SUPPORT ON STUDIO */}
        Resend Code
      </div>
      <Button
        onClick={onSubmitVerification}
        block
        className="mt-20"
        disabled={!verificationCode.length}
        style={{
          color: `#${popup?.nextButtonStyle?.color}`,
          fontSize: popup?.nextButtonStyle?.fontSize,
          borderRadius: popup?.nextButtonStyle?.borderRadius,
          backgroundColor: `#${popup?.nextButtonStyle?.backgroundColor}`,
          fontFamily: 'inherit',
        }}
      >
        {popup?.confirmButtonText || 'CONFIRM'}
      </Button>
    </>
  );
};
