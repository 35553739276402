export const SuccessIcon = () => {
  return (
    <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <path d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z" fill="white" />
        <path d="M40.4996 77.5753C61.2521 77.5753 78.0753 60.7521 78.0753 39.9996C78.0753 19.2471 61.2521 2.42383 40.4996 2.42383C19.7471 2.42383 2.92383 19.2471 2.92383 39.9996C2.92383 60.7521 19.7471 77.5753 40.4996 77.5753Z" stroke="url(#paint0_linear_3625_7642)" strokeWidth="4.84848" />
      </g>
      <path d="M26.7638 37.8324C26.4728 37.8316 26.1852 37.8936 25.9203 38.0141C25.6555 38.1346 25.4198 38.3107 25.2292 38.5305C25.0379 38.7503 24.8958 39.0085 24.8123 39.2876C24.7288 39.5668 24.706 39.8606 24.7452 40.1493C24.7845 40.4381 24.8849 40.7151 25.0399 40.9619C25.1949 41.2086 25.4007 41.4195 25.6438 41.5802L35.2316 48.882C35.6264 49.1798 36.1152 49.3254 36.6086 49.292C37.1019 49.2586 37.5667 49.0485 37.9177 48.7002C38.485 48.1329 42.308 44.2178 46.8074 39.5972L51.9395 34.339C54.0026 32.2178 55.4935 30.6857 55.6656 30.5136C56.0494 30.1297 56.2651 29.6091 56.2651 29.0663C56.2651 28.5234 56.0494 28.0029 55.6656 27.619C55.2817 27.2352 54.7611 27.0195 54.2183 27.0195C53.6755 27.0195 53.1549 27.2352 52.771 27.619C52.2474 28.1427 49.2583 31.2142 45.7965 34.7705L43.8765 36.7439C40.4607 40.2517 38.0583 42.7196 36.6983 44.1063L36.2765 44.5378L28.165 38.3584C27.7779 38.0176 27.2794 37.8305 26.7638 37.8324Z" fill="url(#paint1_linear_3625_7642)" stroke="url(#paint2_linear_3625_7642)" strokeWidth="0.606061" />
      <defs>
        <linearGradient id="paint0_linear_3625_7642" x1="28.1076" y1="5.4219" x2="58.6878" y2="69.1808" gradientUnits="userSpaceOnUse">
          <stop stopColor="#58BFEC" />
          <stop offset="1" stopColor="#3C7CDE" />
        </linearGradient>
        <linearGradient id="paint1_linear_3625_7642" x1="35.2953" y1="27.9082" x2="42.3604" y2="48.7625" gradientUnits="userSpaceOnUse">
          <stop stopColor="#58BFEC" />
          <stop offset="1" stopColor="#3C7CDE" />
        </linearGradient>
        <linearGradient id="paint2_linear_3625_7642" x1="35.2953" y1="27.9082" x2="42.3604" y2="48.7625" gradientUnits="userSpaceOnUse">
          <stop stopColor="#58BFEC" />
          <stop offset="1" stopColor="#3C7CDE" />
        </linearGradient>
      </defs>
    </svg>
  )
}