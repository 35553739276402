import { useEffect } from 'react';
import { useActiveSettingPreviewStore } from 'store/ActiveSettingPreviewStore';

/* 
  This event listener listen selectedPreview event from studio, and update in from useActiveSettingPreviewStore.
  This event listener listen event if event.data has selectedPreview property
 */
export const useSettingPreviewListener = () => {
  const { setSelectedPreview } = useActiveSettingPreviewStore();
  const handleWindowEventsFromStudio = (event: MessageEvent): void => {
    // eslint-disable-next-line no-prototype-builtins
    if (event.origin !== process.env.REACT_APP_STUDIO_HOST || !event?.data?.hasOwnProperty('selectedPreview')){
      return ;
    }
    const receivedData = event.data?.selectedPreview || null;
    setSelectedPreview(receivedData);
  };
  
  useEffect(() => {
    window.addEventListener('message', handleWindowEventsFromStudio);
    return () => window.removeEventListener('message', handleWindowEventsFromStudio);
  }, [setSelectedPreview]);
};
