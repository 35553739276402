import { FormEvent } from 'react';
import { InputStyled } from './styled';

interface Props {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  style?: object;
  type?: 'string' | 'number' | 'email';
  inputColor?: string;
}

export const Input = ({
  value,
  onChange,
  placeholder = '',
  error = '',
  type = 'string',
  style = {},
  inputColor = 'black',
}: Props) => {
  return (
    <div className="relative flex flex-col">
      <InputStyled
        type={type}
        value={value || ''}
        onChange={(event: FormEvent<HTMLInputElement>) => onChange(event.currentTarget.value)}
        placeholder={placeholder}
        style={{ ...style }}
        inputColor={inputColor}
      />
      {!!error && (
        <span className="absolute bottom-0 mb-[-20px] mt-1 max-w-full truncate text-sm text-error">{error}</span>
      )}
    </div>
  );
};
