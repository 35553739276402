import { useState, ReactNode, useEffect } from 'react';
import { UserBaseIdentifierType } from '@flarie/common';
import { shallow } from 'zustand/shallow';
import { SignInSectionTypes } from 'enums/SignInSectionTypes';
import { Modal } from 'components/common/modal/Modal';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { SignInForm } from 'components/sign-in-form/SignInForm';
import { usePlayerStore } from 'store/PlayerStore';
import { checkKnownPlayer } from 'utils/check-known-player';
import { PlayerVerificationForm } from 'components/player-verification-form/PlayerVerificationForm';
import { PlayerParameterForm } from 'components/player-parameter-form/PlayerParameterForm';
import { PlayerAuthComplete } from 'components/player-auth-complete/PlayerAuthComplete';
import { checkIfVerifiedPlayer } from 'services/LocalStorageService';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const SignInModal = ({ isOpen, onClose }: Props) => {
  const [userBase] = useGameCenterSettingStore((state) => [state.userBase], shallow);
  const [gameCenterPlayer] = usePlayerStore((store) => [store.gameCenterPlayer], shallow);

  const [formSectionType, setFormSectionType] = useState<SignInSectionTypes>(SignInSectionTypes.SIGN_IN_USER_FORM);
  const [isSignUpComplete, setIsSignUpComplete] = useState<boolean>(false);
  const [isKnownPlayer, setIsKnownPlayer] = useState<boolean>(false);
  const [previousTab, setPreviousTab] = useState<SignInSectionTypes | null>(null);

  useEffect(() => {
    if (!gameCenterPlayer || !userBase) {
      return;
    }
    const isAllParamsFilled = checkKnownPlayer(gameCenterPlayer, userBase.parameters);
    if (userBase.identifierType === UserBaseIdentifierType.USER_INPUT) {
      if (!isSignUpComplete) {
        setFormSectionType(SignInSectionTypes.SIGN_IN_USER_FORM);
        if (previousTab) {
          setPreviousTab(null);
        }
      } else {
        if (!isAllParamsFilled) {
          setPreviousTab(() => formSectionType);
          setFormSectionType(() => SignInSectionTypes.PLAYER_PARAMETER_FORM);
          return;
        }
        if (!isKnownPlayer) {
          setPreviousTab(() => null);
          setFormSectionType(() => SignInSectionTypes.SIGN_UP_COMPLETE);
          return;
        }
        if (isKnownPlayer && !gameCenterPlayer.isVerified) {
          // && !gameCenterPlayer.isVerified) {
          setPreviousTab(() => formSectionType);
          setFormSectionType(() => SignInSectionTypes.VERIFICATION_FORM);
          return;
        }
        if (checkIfVerifiedPlayer(userBase.gameCenterId, gameCenterPlayer.email)) {
          setPreviousTab(() => null);
          setFormSectionType(() => SignInSectionTypes.SIGN_IN_USER_FORM);
          return;
        }
        onClose();
      }
    }
    if (userBase.identifierType === UserBaseIdentifierType.URL_PARAMETER) {
      if (!isSignUpComplete) {
        setFormSectionType(SignInSectionTypes.SIGN_IN_UUID_FORM);
        if (previousTab) {
          setPreviousTab(null);
        }
      } else {
        if (!isAllParamsFilled) {
          setPreviousTab(() => formSectionType);
          setFormSectionType(() => SignInSectionTypes.PLAYER_PARAMETER_FORM);
          return;
        }
        onClose();
      }
    }
  }, [gameCenterPlayer, isSignUpComplete, isKnownPlayer]);

  if (!userBase) {
    return <div />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="text-dark"
      contentStyles={{ backgroundColor: `#${userBase.loginBackgroundColor}`, color: `#${userBase.loginTextColor}` }}
      modalFooter={<img src={userBase.logoImage} alt="Logo" className="object-contain" />}
      showBack={!!previousTab}
      onBackClick={() => {
        if (previousTab) setFormSectionType(previousTab);
      }}
      closeIconColor={`#${userBase.loginTextColor}`}
    >
      <SignInModal.Content
        formSectionType={formSectionType}
        onCloseModal={onClose}
        onSignUpComplete={() => setIsSignUpComplete(true)}
        setIsKnownPlayer={setIsKnownPlayer}
      />
    </Modal>
  );
};

interface ContentProps {
  formSectionType: SignInSectionTypes;
  onCloseModal: () => void;
  onSignUpComplete: () => void;
  setIsKnownPlayer: (value: boolean) => void;
}

SignInModal.Content = ({ formSectionType, onCloseModal, onSignUpComplete, setIsKnownPlayer }: ContentProps) => {
  if (
    formSectionType === SignInSectionTypes.SIGN_IN_USER_FORM ||
    formSectionType === SignInSectionTypes.SIGN_IN_UUID_FORM
  ) {
    return <SignInForm onSignUpComplete={onSignUpComplete} setIsKnownPlayer={setIsKnownPlayer} />;
  }
  if (formSectionType === SignInSectionTypes.PLAYER_PARAMETER_FORM) {
    return <div className='p-8'><PlayerParameterForm /></div>;
  }
  if (formSectionType === SignInSectionTypes.VERIFICATION_FORM) {
    return <div className='p-8'><PlayerVerificationForm onCloseModal={onCloseModal} /></div>;
  }
  if (formSectionType === SignInSectionTypes.SIGN_UP_COMPLETE) {
    return <div className='p-8'><PlayerAuthComplete onCloseModal={onCloseModal} /></div>;
  }
  return <div />;
};

SignInModal.Title = ({ children, className = '' }: { children: ReactNode; className?: string }) => (
  <div className={`pt-2 text-center text-[3vh] leading-tight ${className}`}>{children}</div>
);
