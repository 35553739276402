/* eslint-disable no-useless-escape */
import { FormInputType } from '@flarie/common';

export const INPUT_PATTERN_SET = new Set([
  FormInputType.USERNAME,
  FormInputType.COMPANY,
  FormInputType.LAST_NAME,
  FormInputType.FIRST_NAME,
  FormInputType.FULL_NAME,
  FormInputType.CITY,
  FormInputType.ADDRESS,
  FormInputType.OTHER1,
  FormInputType.OTHER2,
  FormInputType.OTHER3,
  FormInputType.OTHER4,
]);

export const NUMBER_TYPES = new Set([FormInputType.AGE]);

export const PHONE_PATTERN = `^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$`;

export const INPUT_PATTERN = '^[^~!@#%^*()+={}<£>;:,?/©°%$]*$';

export const ZIP_PATTERN = '^[A-Za-z0-9_.]+$';
