import { RenderHtmlText } from 'utils/render-html-text';

interface Props {
  label: string;
  checked: boolean;
  onClick: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
  id?: string;
}

export const CheckBox = ({
  id = `flarie-${Math.random()}`,
  label,
  onClick,
  checked,
  disabled,
  className = '',
}: Props) => {
  // const checkBoxClassName =
  //   "checked:after:content-[''] checked:after:absolute checked:after:top-1 checked:after:left-[2px] checked:after:w-3 checked:after:h-[6px] checked:after:border-l-2 checked:after:border-b-2 checked:after:border-primary checked:after:rotate-[-45deg]";
  return (
    <div className={`relative mt-1 flex cursor-pointer ${className}`}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={() => onClick(!checked)}
        className="h-5 min-h-[20px] w-5 min-w-[20px] rounded border-2 outline-none"
      />
      <label htmlFor={id} className="ml-2 text-[18px] leading-tight">
        <RenderHtmlText htmlText={label} />
      </label>
    </div>
  );
};
