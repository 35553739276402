import { useRef } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, MenuRef } from 'rc-menu';
import 'rc-dropdown/assets/index.css';

export const BUSINESS_REGIONS = ['EMEA', 'NA', 'APAC', 'LATAM'];

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: any;
}

export const BusinessRegionInput = ({ placeholder, value, onChange, style = {} }: Props): JSX.Element => {
  const dropdownMenuRef = useRef<MenuRef>(null);

  const onSelect = ({ key }: { key: string }): void => {
    onChange(key);
  };

  const menu = (
    <Menu onSelect={onSelect} ref={dropdownMenuRef} style={{ width: 'inherit', maxHeight: 200, overflow: 'auto' }}>
      {BUSINESS_REGIONS.map((region: string) => (
        <MenuItem key={region} className="flex h-[40px] cursor-pointer items-center justify-start text-[18px]">
          <span className="px-6">{region}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} overlayStyle={{ overflowX: 'hidden' }} animation="slide-up">
      <div
        className="width-full flex h-[56px] items-center justify-start border-0 border-b-2 border-b-black px-3 text-lg outline-none duration-300 ease-in-out focus:border-b-primary focus:outline-none"
        style={style}
      >
        <div className="text-[18px]">{value || placeholder}</div>
        <span className="dropdown-caret" />
      </div>
    </Dropdown>
  );
};
