import { Modal } from 'components/common/modal/Modal';
import { PlayerParameterForm } from 'components/player-parameter-form/PlayerParameterForm';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { shallow } from 'zustand/shallow';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
export const AddInfoModal = ({ isOpen, onClose }: Props) => {
  const [userBase] = useGameCenterSettingStore((state) => [state.userBase], shallow);

  if (!userBase?.isEnable) {
    return <div />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="text-dark"
      contentStyles={{ backgroundColor: `#${userBase.loginBackgroundColor}`, color: `#${userBase.loginTextColor}` }}
      isFloatingModal
    >
      <PlayerParameterForm isAddInfoData />
    </Modal>
  );
};
