import { ComponentType } from '@flarie/common/dist/enums/ComponentType';
import { FormInputType, IGameCenterFormInput } from '@flarie/common';
import { Input } from 'components/common/input/Input';
import { getOptionArray } from 'utils/get-options-array';
import { CheckBox } from 'components/common/check-box/CheckBox';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { DropdownInput } from './DropdownInput';
import { RadioInput } from './RadioInput';

interface Props {
  componentType: ComponentType;
  componentValues: string;
  value: string | number;
  onChange: (value: string | boolean) => void;
  placeholder: string;
  type?: FormInputType;
}

export const isMandatory = (type: FormInputType, parameters: IGameCenterFormInput[] = []): boolean => {
  const found = parameters.find((item) => item.type === type && item.isMandatory);
  return !!found;
};

export const CustomComponent = ({ componentType, componentValues, value, onChange, placeholder, type }: Props) => {
  const userBase = useGameCenterSettingStore((state) => state.userBase);
  if (componentType === ComponentType.CHECKBOX) {
    return (
      <div className="flex flex-row">
        <CheckBox
          checked={Boolean(value)}
          label={`${placeholder}${isMandatory(type as FormInputType, userBase?.parameters) ? ' *' : ''}`}
          onClick={onChange}
          className="mb-1"
        />
      </div>
    );
  }
  if (componentType === ComponentType.DROPDOWN) {
    return (
      <DropdownInput
        dropdownOptions={getOptionArray(componentValues)}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    );
  }
  if (componentType === ComponentType.RADIO) {
    return (
      <RadioInput
        radioOptions={getOptionArray(componentValues)}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
    );
  }
  return <Input value={value as string} onChange={onChange} placeholder={placeholder} />;
};
