import { IGameCenterPlayer } from '@flarie/common';
import { PlayerErrorType } from 'enums/PlayerErrorType';
import { removeLocalGameCenterPlayer, setLocalGameCenterData } from 'services/LocalStorageService';
import { create } from 'zustand';

interface Props {
  playerCoins: number;
  isKnownPlayer: boolean;
  gameCenterPlayer: IGameCenterPlayer | null;
  isPlayerError: PlayerErrorType | null;
  playerHashId: string;
  setIsPlayerError: (value: PlayerErrorType) => void;
  setGameCenterPlayer: (data: IGameCenterPlayer) => void;
  setIsKnownPlayer: (value: boolean) => void;
  setPlayerCoins: (value: number) => void;
  removeGameCenterPlayer: (gameCenterId: string) => void;
  setPlayerHashId: (playerHashId: string) => void;
}

export const usePlayerStore = create<Props>((set) => ({
  playerCoins: 0,
  isKnownPlayer: false,
  gameCenterPlayer: null,
  isPlayerError: null,
  playerHashId: '',
  setIsPlayerError: (isPlayerError) => set({ isPlayerError }),
  setGameCenterPlayer: (gameCenterPlayer: IGameCenterPlayer) => {
    setLocalGameCenterData(gameCenterPlayer.gameCenterId, gameCenterPlayer);
    set({ gameCenterPlayer });
  },
  removeGameCenterPlayer: (gameCenterId: string) => {
    removeLocalGameCenterPlayer(gameCenterId);
    set({ gameCenterPlayer: null });
  },
  setIsKnownPlayer: (isKnownPlayer: boolean) => ({ isKnownPlayer }),
  setPlayerCoins: (playerCoins: number) => {
    set({
      playerCoins,
    });
  },
  setPlayerHashId: (playerHashId: string) => {
    set({
      playerHashId,
    });
  },
}));
