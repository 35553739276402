import { CountDownTimer } from 'components/common/count-down-timer/CountDownTimer';
import { FC } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { getLockBackground } from './styles';

interface Props {
  countDownDate?: string;
  days?: number;
  backgroundImageUrl?: string;
}

const Lock: FC<Props> = ({ countDownDate, days = 0, backgroundImageUrl = '' }) => {
  const detail = useGameCenterSettingStore((state) => state.detail);
  const styles = getLockBackground(backgroundImageUrl);
  return (
    <div
      className="absolute left-0 top-0 flex h-full w-full content-center items-center justify-center text-center"
      style={styles.lockBackground}
    >
      <div>
        <img className="ml-auto mr-auto max-w-[36px]" data-testid="lock-image" src={detail?.lockImage} alt="Game" />
        {countDownDate && <CountDownTimer days={days} createDate={countDownDate} />}
       
      </div>
    </div>
  );
};
export default Lock;
