import { IReplaceUrlParamsPayload } from 'interfaces/ReplaceUrlParams';
/*
    This function replaces dynamic placeholders within a URL string with corresponding values from a payload object.

    Parameters: 
    url (string): The URL string containing dynamic placeholders to be replaced.
    payload (IReplaceUrlParamsPayload): An object containing key-value pairs where keys correspond to placeholders and values are the replacements.
    
    Example: 
    url: https://example.com?playerId={player.playerId}&uuid={player.uuid}&email={player.email}&phone={player.phone}
    payload:{
        playerId: '123456',
        uuid: '7890',
        email: 'example@example.com',
        phone: '1234567890'
    }

    output: https://example.com?playerId=123456&uuid=7890&email=example@example.com&phone=1234567890
*/
export const replaceUrlParameters = (url: string, payload: IReplaceUrlParamsPayload): string => {
  return url?.replace(/{([^}]+)}/g, (match, key) => {
    switch (key) {
      case 'player.playerId':
        return payload?.playerId || '';
      case 'player.uuid':
        return payload?.uuid || '';
      case 'player.email':
        return payload?.email || '';
      case 'player.phone':
        return payload?.phone || '';
      default:
        return '';
    }
  });
};
