import { IGameCenterReward, IGameCenterRewardSettings, Size } from '@flarie/common';
import { PageContainer } from 'components/page-wrapper/PageContainer';
import { RewardConfirmation } from 'components/popup/RewardConfirmation';
import { CoinIcons } from 'components/ui/CoinIcon';
import useGameCenterPlayer from 'hooks/useGameCenterPlayer';
import { FC, useState } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { addThousandSeparator } from 'utils/helper';
import { shallow } from 'zustand/shallow';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import useGameCenterData from 'hooks/useGameCenterData';
import { useDimension } from 'hooks/useDimension';
import { CloseIcon } from 'components/icons/CloseIcon';
import { getRewardDetailStyle } from './styles';

interface Props {
  reward: IGameCenterReward;
  onClose: () => void;
}

export const RewardDetails: FC<Props> = ({ reward, onClose }) => {
  const [detail, rewardSettings, userBase] = useGameCenterSettingStore(
    (state) => [state.detail, state.rewardSettings, state.userBase],
    shallow,
  );
  const styles = getRewardDetailStyle(rewardSettings || ({} as IGameCenterRewardSettings));
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { refetchGameCenterPlayer } = useGameCenterPlayer(
    detail?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );
  const { refetchGameCenter } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  const onCloseModal = () => {
    refetchGameCenterPlayer();
    refetchGameCenter();
    setOpenModal(false);
  };
  const { width } = useDimension();

  return (
    <PageContainer pageStyle={styles.pageBackground} className="h-full">
      <div className="relative  w-full overflow-auto px-8 pt-12 pb-10 text-center">
        <div className="relative">
          <div className="mb-5 text-left">
            <button onClick={onClose} type="button" className="w-8 h-8 rounded-full bg-black bg-opacity-80 text-center flex items-center justify-center">
              <CloseIcon color='#FFFFFF' />
            </button>
          </div>
          <div className="row justify-center">
            <div className={`${reward?.size === Size.HALF ? 'col-2' : 'col-1'}`}>
              <img className="ml-auto mr-auto w-full" src={reward?.rewardImage} alt="Reward" />
            </div>
          </div>
          <DynamicFontLoader fontId={rewardSettings?.headerFontStyle.font || ''} fontName="reward-header-font">
            <div className="text-xl- mt-4 mb-4 block text-center" style={styles.headerFontStyle}>
              {reward?.name}
              <div className="flex items-center justify-center">
                <CoinIcons size={Number(styles.headerFontStyle.fontSize?.replace('px', '')) || 30} /> &nbsp;
                {addThousandSeparator(reward?.rewardPrice)}
              </div>
            </div>
          </DynamicFontLoader>

          <div className="mb-[4vh] h-[1px] w-full bg-white opacity-30"></div>
          <DynamicFontLoader
            fontId={rewardSettings?.descriptionTextStyle.font || ''}
            fontName="reward-description-font"
          >
            <p className="mb-[13vh] whitespace-pre-wrap pb-24 px-[8%] leading-normal" style={styles.descriptionTextStyle}>
              {reward?.rewardItemDescriptionText}
            </p>
          </DynamicFontLoader>
          <DynamicFontLoader fontId={rewardSettings?.buttonStyle?.font || ''} fontName="reward-button-font">
            <button
              type="button"
              className="fixed left-0 right-0 ml-auto mr-auto block min-h-[56px]"
              onClick={() => setOpenModal(true)}
              style={{ ...styles.buttonStyle, width: `${width * 0.7}px` }}
            >
              {rewardSettings?.buttonText}
            </button>
          </DynamicFontLoader>
        </div>
      </div>
      {openModal && <RewardConfirmation reward={reward} openModal={openModal} onCloseModal={onCloseModal} />}
    </PageContainer >
  );
};
