import React, { useEffect } from 'react';

interface DynamicFontLoaderProps {
  fontId: string;
  fontName: string;
  children: React.ReactNode;
  className?: string;
}
interface IReturnUrls {
  bold: string;
  regular: string;
}
const loadedFonts: Set<string> = new Set();

export const getGameFontUrl = (fontId: string): IReturnUrls => {
  const bucketUrlPrefix = `${process.env.REACT_APP_AWS_BUCKET_NAME}/fonts`;
  return {
    bold: `${bucketUrlPrefix}/${fontId}/bold`,
    regular: `${bucketUrlPrefix}/${fontId}/regular`,
  };
};

export const DynamicFontLoader: React.FC<DynamicFontLoaderProps> = ({ fontId, fontName, children, className }) => {
  const fontUrlObject = getGameFontUrl(fontId);
  const fonts = {
    otfBold: `url(${fontUrlObject.bold}.otf) format('opentype')`,
    ttfBold: `url(${fontUrlObject.bold}.ttf) format('truetype')`,
    ttfRegular: `url(${fontUrlObject.regular}.ttf) format('truetype')`,
    otfRegular: `url(${fontUrlObject.regular}.otf) format('opentype')`,
  };
  useEffect(() => {
    // TODO: The uses of `loadedFonts` set is being removed in FL-4366, need to confirm and update/remove this code
    if (loadedFonts.has(fontName)) {
      return;
    }

    const fontFaceStyle = document.createElement('style');
    fontFaceStyle.textContent = `
      @font-face {
        font-family: "${fontName}";
        src: ${fonts.otfBold}, ${fonts.ttfBold};
        font-weight: bold;
        font-style: normal;
      }
      @font-face {
        font-family: "${fontName}";
        src: ${fonts.otfRegular}, ${fonts.ttfRegular};
        font-weight: normal;
        font-style: normal;
      }
    `;
    document.head.appendChild(fontFaceStyle);
    // eslint-disable-next-line consistent-return
    return () => {
      document.head.removeChild(fontFaceStyle);
    };
  }, [fontId]);

  return (
    <div className={className} style={{ fontFamily: `${fontName}, bebas` }}>
      {children}
    </div>
  );
};
