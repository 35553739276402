import { IStyle } from "@flarie/common";

interface IStyleObject {
  gameImageBackgroundStyle?: Partial<IStyle>;
}

export const getGameStyle = ({gameImageBackgroundStyle}:IStyleObject) => {
  return {
    gameImageBackgroundStyle :{
      backgroundColor: `#${gameImageBackgroundStyle?.backgroundColor}`,
      borderRadius: gameImageBackgroundStyle?.borderRadius,
    },
  }
}