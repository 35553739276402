import { IGameCenterFormInput, IGameCenterPlayer } from '@flarie/common';
import { PageContainer } from 'components/page-wrapper/PageContainer';
import { GameCenterEmitMessage } from 'enums/GameCenterEmitMessage';
import { GamePlayState } from 'enums/GamePlayState';
import useGameCenterData from 'hooks/useGameCenterData';
import useGameCenterPlayer from 'hooks/useGameCenterPlayer';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { IGameDimension } from 'interfaces/GameDimension';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { usePlayerStore } from 'store/PlayerStore';
import { checkKnownPlayer } from 'utils/check-known-player';
import { getOverlayContainerDimensions } from 'utils/get-overlay-container-dimensions';
import { addThousandSeparator, getPlayerIdIdentifierByGameCenter } from 'utils/helper';
import { CloseIcon } from 'components/icons/CloseIcon';
import { getPlayedGamesMap, setPlayedGamesMap } from 'services/LocalStorageService';

interface Props {
  game: string;
  gameId: string;
  onClose: () => void;
}

const GameDetails: FC<Props> = ({ game, onClose, gameId }) => {
  const [searchParams] = useSearchParams();
  const urlParameterToken = searchParams.get('token');
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [gameDimension, setGameDimension] = useState<IGameDimension>({ width: 0, height: 0 });
  const gameCenterPlayer = usePlayerStore((state) => state.gameCenterPlayer);

  const [detail, userBase] = useGameCenterSettingStore((state) => [state.detail, state.userBase]);
  const playerCoins = usePlayerStore((state) => state.playerCoins);
  const { playerId, identifierValue } = getPlayerIdIdentifierByGameCenter(
    detail?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );
  const playedGames = getPlayedGamesMap(detail?.gameCenterId ?? '');

  const [isPlaying, setIsPlaying] = useState(false);
  const [isGameLoaded, setIsGameLoaded] = useState(false);
  const [hasPlayedGame, setHasPlayedGame] = useState(false);

  const { refetchGameCenterPlayer } = useGameCenterPlayer(
    detail?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );
  const { refetchGameCenter } = useGameCenterData(
    userBase?.gameCenterId as string,
    userBase?.isEnable ? userBase?.identifierType : undefined,
  );

  const onMessageReceivedFromGameEngine = (event: any): void => {
    if (event && event.data && event.data[GameCenterEmitMessage.ENGINE_UPDATE_STATUS]) {
      const { status } = event.data[GameCenterEmitMessage.ENGINE_UPDATE_STATUS];
      if (status === GamePlayState.BEFORE_START) {
        setIsGameLoaded(true);
      }
      if (status === GamePlayState.ACTIVE) {
        setIsPlaying(true);
      } else {
        setIsPlaying(false);
      }
    }
    if (event && event.data && event.data[GameCenterEmitMessage.ENGINE_GAME_OVER_SCORE]) {
      setHasPlayedGame(true);
      refetchGameCenterPlayer();
    }
  };

  useEffect(() => {
    if (!windowHeight || !windowHeight) return;

    const { width, height } = getOverlayContainerDimensions(windowWidth, windowHeight);
    setGameDimension({ width, height });
  }, [windowHeight, windowWidth]);

  const floatingStyles = useMemo(() => {
    const top = (windowHeight - gameDimension.height) / 2;
    const left = (windowWidth - gameDimension.width) / 2;
    const height = (62 / 1334) * windowHeight;
    const width = (80 / 1334) * windowHeight;
    const radius = (24 / 1334) * windowHeight;
    const fontSize = (28 / 1334) * windowHeight;
    return { top, left, width, height, radius, fontSize };
  }, [gameDimension]);

  useEffect(() => {
    window.addEventListener('message', onMessageReceivedFromGameEngine);
    return () => window.removeEventListener('message', onMessageReceivedFromGameEngine);
  }, [onMessageReceivedFromGameEngine]);

  useEffect(() => {
    const isAllParamsFilled = checkKnownPlayer(
      gameCenterPlayer as unknown as IGameCenterPlayer,
      userBase?.parameters as IGameCenterFormInput[],
    );
    if (userBase?.isEnable && !isAllParamsFilled) {
      onClose();
    }
  }, []);

  let gameFullUrl = `${process.env.REACT_APP_FLARIE_GAME_ENGINE_HOST
    }/games/${game}/${gameId}?playerId=${playerId}&identifierValue=${encodeURIComponent(identifierValue)}&hasPlayedFromGameCenter=${playedGames[gameId] ?? false
    }`;
  if (urlParameterToken) {
    gameFullUrl = `${gameFullUrl}&token=${urlParameterToken}`;
  }
  const onCloseGame = () => {
    if (hasPlayedGame && detail?.gameCenterId && !playedGames[gameId]) {
      playedGames[gameId] = true;
      setPlayedGamesMap(detail.gameCenterId, playedGames);
    }
    onClose();
    refetchGameCenter();
  };

  return (
    <PageContainer pageStyle={{}}>
      <div className="fixed inset-0 left-0 top-0">
        {!isPlaying && isGameLoaded && (
          <div style={{ position: 'fixed', top: floatingStyles.top, left: floatingStyles.left }}>
            <div className="float-left pt-4 pl-4">
              <div className="flex flex-row">
                <button onClick={onCloseGame} type="button" className="rounded-full bg-black bg-opacity-80 text-center flex items-center justify-center"
                  style={{
                    height: floatingStyles.height,
                    width: floatingStyles.height
                  }}>
                  <CloseIcon color='#FFFFFF' />
                </button>
                {detail?.isWalletActive && (
                  <span
                    className="ml-1 flex items-center justify-center rounded-full bg-black bg-opacity-80 px-2 pt-[2px] text-center text-lg text-white"
                    style={{
                      height: floatingStyles.height,
                      minWidth: floatingStyles.width,
                      borderRadius: floatingStyles.radius,
                      fontSize: floatingStyles.fontSize,
                      fontWeight: 'bold',
                    }}
                  >
                    {addThousandSeparator(playerCoins || 0)}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        <iframe src={gameFullUrl} title={game} width="100%" height="100%" className="w-full 100%" />
      </div>
    </PageContainer >
  );
};
export default GameDetails;
