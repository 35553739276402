import { create } from 'zustand';

interface Props {
  token: string;
  setToken: (value: string) => void;
}

export const useIngoStore = create<Props>((set) => ({
  token: '',
  setToken : (token) => set({token})
}));
