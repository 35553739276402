import { IStyle } from '@flarie/common';
import { FC } from 'react';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import { getSectionStyle } from './styles';

interface Props {
  title?: string;
  descriptionText?: string;
  className?: string;
  children: React.ReactNode;
  headerFontStyle: Partial<IStyle>;
  descriptionStyle?: Partial<IStyle>;
  moduleId: string;
}

const Section: FC<Props> = ({
  title = '',
  className = '',
  children,
  headerFontStyle,
  descriptionText,
  descriptionStyle,
  moduleId,
}) => {
  const styles = getSectionStyle({ headerFontStyle, descriptionStyle });
  return (
    <section className={`pb-3 ${className} col-span-2 `}>
      {title && (
        <DynamicFontLoader fontId={headerFontStyle?.font || ''} fontName={`section-title-font-${moduleId}`}>
          <h2 className="mb-3" style={styles.headerFontStyle}>
            {title}
          </h2>
        </DynamicFontLoader>
      )}
      {descriptionText && (
        <DynamicFontLoader fontId={descriptionStyle?.font || ''} fontName={`section-description-font-${moduleId}`}>
          <div className="mb-3" style={styles.descriptionStyle}>
            {descriptionText}
          </div>
        </DynamicFontLoader>
      )}
      <div className="section-content">{children}</div>
    </section>
  );
};
export default Section;
