/* eslint-disable dot-notation */
import { IGameCenterDetail } from '@flarie/common';

export const getPageWrapperStyle = (gameCenterDetail: IGameCenterDetail) => {
  const pageBackground = {
    fontFamily: 'inherit',
    color: `#${gameCenterDetail.textColor}`,
  };
  if (gameCenterDetail.backgroundImage) {
    pageBackground['backgroundImage'] = `url(${gameCenterDetail.backgroundImage})`;
  } else {
    pageBackground['background'] = `#${gameCenterDetail.backgroundColor}`;
  }

  return { pageBackground };
};
