import { FormInputType } from '@flarie/common';

export const DEFAULT_GC_WIDTH = 500;

export const BUSINESS_REGIONS = ['EMEA', 'NA', 'APAC', 'LATAM'];

export const PARAM_FORM_INPUTS = [
  FormInputType.PARAM1,
  FormInputType.PARAM2,
  FormInputType.PARAM3,
  FormInputType.PARAM4,
  FormInputType.PARAM5,
  FormInputType.PARAM6,
  FormInputType.PARAM7,
  FormInputType.PARAM8,
  FormInputType.PARAM9,
  FormInputType.PARAM10,
];