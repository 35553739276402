import { IOptionsArray } from "interfaces/OptionArray";

/**
 * 
 * @param input comma separated string. i.e:- "One, Two, Three"
 * @returns an array of objects like:- [{name: One, type: One}, {name: Two, type: Two}, {name: Three, type: Three}]
 */
export const getOptionArray = (input: string | undefined): IOptionsArray[] => {
  if (!input) return [];
  return input.split(',').map((value) => ({
    name: value.trim(),
    type: value.trim(),
  }));
};