export const getSectionStyles = (width: number): any => {
  return {
    containerStyle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      // maxWidth: 500,
      width: `${width}px`,
      marginLeft: 'auto',
      marginRight: 'auto',
      bottom: 0,
      left: 0,
      right: 0,
      height: 70,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      backgroundColor: 'white',
      cursor: 'pointer',
    },
    stickyTextStyle: {
      color: '#000',
      fontSize: 18,
    },
  };
};
