import React from 'react';

interface Props {
  id?: string;
  label: React.ReactNode;
  checked: boolean;
  onClick: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
}

export const RadioInputItem: React.FC<Props> = ({
  id = `flarie-${Math.random()}`,
  label,
  checked,
  onClick,
  disabled = false,
  className = '',
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(!checked);
    }
  };

  // TODO:- we've to remove the global style classes in future
  return (
    <div className="my-2">
      <label className={`custom-control custom-radio ${className}`} htmlFor={id}>
        <input className="custom-control-input" type="radio" checked={checked} onChange={handleClick} id={id} />
        <span className="custom-control-label break-words">{label}</span>
      </label>
    </div>
  );
};
