import { useDraftDrawerStore } from 'store/DraftDrawerStore';
import {
  DrawerCollapseStyled,
  DrawerStyled,
  ImageContainerStyled,
  LearnMoreButtonStyled,
  TextContainerStyled,
} from './styled';

export const GameDraftDrawer = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useDraftDrawerStore((store) => [store.isCollapsed, store.setIsCollapsed]);

  const onClickButton = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event?.stopPropagation();
    window.open(process.env.REACT_APP_LEARN_MORE_LINK, '_blank');
  };

  return (
    <DrawerStyled role="button" onClick={(): void => setIsCollapsed(!isCollapsed)} isCollapsed={isCollapsed}>
      <ImageContainerStyled>
        <TextContainerStyled>This is a Draft created in Flarie Studio.</TextContainerStyled>
        <LearnMoreButtonStyled onClick={onClickButton}>Learn More</LearnMoreButtonStyled>
      </ImageContainerStyled>
      <DrawerCollapseStyled isCollapsed={isCollapsed}>
        <GameDraftDrawer.DrawerIcon />
      </DrawerCollapseStyled>
    </DrawerStyled>
  );
};

GameDraftDrawer.DrawerIcon = (): JSX.Element => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 7.5L7.7348 1.79604C7.33884 1.36708 6.66116 1.36708 6.2652 1.79604L1 7.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
