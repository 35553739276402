import { create } from 'zustand';

interface Props {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}

export const useDraftDrawerStore = create<Props>((set) => ({
  isCollapsed: false,
  setIsCollapsed: (isCollapsed): any => set({ isCollapsed }),
}));
