import React, { FC } from 'react';

interface Props {
  title?: string;
}
const DataLoading: FC<Props> = ({
  title = 'Loading....',
}) => {
  return <div className="text-center pt-[45vh] fixed inset-0 z-50 text-2xl bg-white/75 text-black">{title}</div>;
};
export default DataLoading;
