import { useRef } from 'react';
import Dropdown from 'rc-dropdown';
import Menu, { Item as MenuItem, MenuRef } from 'rc-menu';
import 'rc-dropdown/assets/index.css';
import { GenderType } from '@flarie/common';

const genderList = [
  {
    name: 'Male',
    type: GenderType.MALE,
  },
  {
    name: 'Female',
    type: GenderType.FEMALE,
  },
  {
    name: 'Rather not say',
    type: GenderType.RATHER_NOT_SAY,
  },
];

interface Props {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: object;
}

export const GenderInput = ({ placeholder, value, onChange, style }: Props): JSX.Element => {
  const dropdownMenuRef = useRef<MenuRef>(null);

  const onSelect = ({ key }: { key: string }): void => {
    onChange(key);
  };

  const menu = (
    <Menu onSelect={onSelect} ref={dropdownMenuRef} style={{ width: 'inherit', maxHeight: 200, overflow: 'auto' }}>
      {[...genderList].map(({ name, type }: any) => (
        <MenuItem key={type} className="flex h-[40px] cursor-pointer items-center justify-start text-[18px]">
          <span className="px-6">{name}</span>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <Dropdown trigger={['click']} overlay={menu} overlayStyle={{ overflowX: 'hidden' }} animation="slide-up">
      <div
        className="width-full flex h-[56px] items-center justify-start border-0 border-b-2 border-b-black px-3 outline-none duration-300 ease-in-out focus:border-b-primary focus:outline-none"
        style={style}
      >
        <div className="text-[18px]">{value ? genderList.find(({ type }) => type === value)?.name : placeholder}</div>
        <span className="dropdown-caret" />
      </div>
    </Dropdown>
  );
};
