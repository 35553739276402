import RoutingPath from 'enums/RoutingPath';
import GameCenterPage from 'pages/GameCenterPage';
import { PlayerVerificationPage } from 'pages/PlayerVerificationPage';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const AppRoutes = () => {
  const gameCenterIdPath = '/:gameCenterId';
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="" element={<GameCenterPage />} />
          <Route path={`${RoutingPath.Dashboard}${gameCenterIdPath}`} element={<GameCenterPage />} />
          {/* <Route path={`${gameCenterIdPath}${RoutingPath.Reward}/:id`} element={<RewardPage />} />
          <Route path={`${gameCenterIdPath}${RoutingPath.Coupon}/:id`} element={<CouponPage />} />
          <Route path={`${gameCenterIdPath}${RoutingPath.Game}/:id`} element={<GamePage />} /> */}
          <Route path="verification/success" element={<PlayerVerificationPage success={true} />} />
          <Route path="verification/failed" element={<PlayerVerificationPage success={false} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
