export const BackIcon = ({ color = '#525353' }: { color?: string }) => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1L2.29604 6.2652C1.86708 6.66116 1.86708 7.33884 2.29604 7.7348L8 13"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
