import { IGameCenterDetail } from '@flarie/common';
import { ReactNode } from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { isGCValidOrExpired } from 'utils/is-gc-valid-or-expired';
import { GameCenterExpired } from 'components/game-center-expired/GameCenterExpired';
import { PageHeader } from 'components/page-header/PageHeader';
import { DynamicFontLoader } from 'components/dynamic-font-loader/DynamicFontLoader';
import { useDimension } from 'hooks/useDimension';
import { getPageWrapperStyle } from './styles';

export const PageWrapper = ({ children }: { children: ReactNode }) => {
  const detail = useGameCenterSettingStore((state) => state.detail as IGameCenterDetail);
  const styles = getPageWrapperStyle(detail || ({} as IGameCenterDetail));
  const { expired, notAvailable } = isGCValidOrExpired(detail);
  const { width } = useDimension();

  const renderChildren = () => {
    if (expired) return <GameCenterExpired> This Game center is expired </GameCenterExpired>;
    if (notAvailable) return <GameCenterExpired> This Game center is not yet available </GameCenterExpired>;
    return children;
  };
  return (
    <>
      <DynamicFontLoader fontId={detail.font} fontName="global-font">
        <PageHeader
          backgroundImg={detail.webBackgroundImage}
          backgroundColor={detail.webBackgroundColor}
          fontFamily="global-font"
        />
      </DynamicFontLoader>


      <div style={{ width: `${width}px` }} className="relative flex mx-auto items-center justify-center">
        <DynamicFontLoader fontId={detail.font} fontName="detail-font-family" className="w-full">
          <div
            className="relative mx-auto h-full min-h-[100vh] w-full bg-auto-100 bg-repeat-y shadow-md"
            style={styles.pageBackground}
          >
            {renderChildren()}
          </div>
        </DynamicFontLoader >
      </div >
    </>
  );
};
