/* eslint-disable dot-notation */

export const getLockBackground = (backgroundImage: string) => {
  const lockBackground = {
  };
  if (backgroundImage) {
    lockBackground['backgroundImage'] = `url(${backgroundImage})`;
    lockBackground['backgroundSize'] = 'cover';
    lockBackground['backgroundPosition'] = 'center center';
  } else {
    lockBackground['background'] = `rgba(0, 0, 0, .75)`;
  }

  return { lockBackground };
};
