import { IGameCenterGame, IGameCenterGameModule } from '@flarie/common';
import Game from 'components/game/Game';
import Section from 'components/section/Section';
import { FC } from 'react';

const GameSection: FC<IGameCenterGameModule> = ({
  games,
  headerTitleStyle,
  headerTitle,
  descriptionText,
  descriptionStyle,
  moduleId,
}) => {
  return (
    <Section
      title={headerTitle}
      headerFontStyle={headerTitleStyle}
      descriptionText={descriptionText}
      descriptionStyle={descriptionStyle}
      moduleId={moduleId}
    >
      <div className="grid grid-cols-2 gap-x-5">
        {/* eslint-disable react/no-array-index-key */}
        {games.map((game: IGameCenterGame, i: number) => {
          return <Game key={`game-${i}`} {...game} />;
        })}
      </div>
      
    </Section>
  );
};
export default GameSection;
