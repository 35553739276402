import React from 'react';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';

interface CoinIconProps {
  src?: string;
  size?: number;
  className?: string;
}

export const CoinIcons: React.FC<CoinIconProps> = ({
  src = '',
  size = 20,
  className = '',
}) => {
  const detail = useGameCenterSettingStore((state) => state.detail);
  return (
    <img style={{ width: `${size}px`, height: `${size}px` }} className={`inline-block ${className}`} src={src || detail?.currencyImage} alt="Coin" />
  );
};
