import { IGameCenterPopUp } from "@flarie/common";

export const getPopUpStyle = (popup:IGameCenterPopUp) => {
  return {
    closeButtonStyle :{
      backgroundColor: `#${popup?.closeButtonStyle?.backgroundColor}`,
      color: `#${popup?.closeButtonStyle?.color}`,
      fontSize: popup?.closeButtonStyle?.fontSize,
      borderRadius: popup?.closeButtonStyle?.borderRadius,
      fontFamily: 'inherit',
    },
    confirmButtonStyle :{
      backgroundColor: `#${popup?.confirmButtonStyle?.backgroundColor}`,
      color: `#${popup?.confirmButtonStyle?.color}`,
      fontSize: popup?.confirmButtonStyle?.fontSize,
      borderRadius: popup?.confirmButtonStyle?.borderRadius,
      fontFamily: 'inherit',
    },
    nextButtonStyle :{
      backgroundColor: `#${popup?.nextButtonStyle?.backgroundColor}`,
      color: `#${popup?.nextButtonStyle?.color}`,
      fontSize: popup?.nextButtonStyle?.fontSize,
      borderRadius: popup?.nextButtonStyle?.borderRadius,
      fontFamily: 'inherit',
    },
    pageStyle :{
      backgroundColor: `#${popup?.backgroundColor}`,
      color: `#${popup?.textColor}`,
    },
  }
}