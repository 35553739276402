import { useDimension } from 'hooks/useDimension';

export const DeletedGameCenterMessage = (): JSX.Element => {
  const { width } = useDimension();

  return (
    <div className="t-0 fixed left-0 h-[100%] w-[100%] bg-[#15263a]">
      <div
        style={{ width }}
        className="
        absolute
        left-0
        right-0
        bottom-0
        mx-auto
        flex
        h-screen
        items-center
        justify-center
        overflow-auto
        bg-[url('https://prod-cdn.flarie.com/asset/game-center/deleted-screen-bg.png')]
        bg-cover
        bg-repeat-y
        pb-24
        text-center
        text-[22px]
        font-bold
        leading-[33px]
        text-white
    "
      >
        This Game Center is
        <br />
        no longer available
      </div>
    </div>
  );
};
