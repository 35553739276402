/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IGameCenterDetail, LanguageCode } from '@flarie/common';
import { useEffect } from 'react';
import { RenderHtmlText } from 'utils/render-html-text';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';
import { useGameTermStore } from 'store/GameTermStore';
import { getGameTerms } from 'actions/Actions';
import { CircleLoader } from 'components/circle-loader/CircleLoader';
import { CloseIcon } from 'components/icons/CloseIcon';
import StaticTerms from './StaticTerms';

const DEFAULT_LOGO_URL = 'https://dwfedcf5lscmq.cloudfront.net/studio/60706eb9d459b.png';

interface Props {
  additionalTermsText: string;
  onClose: () => void;
}

export const TermsModal = ({ additionalTermsText, onClose }: Props): JSX.Element => {
  const gameCenterDetail = useGameCenterSettingStore((state) => state.detail) as IGameCenterDetail;

  let languageCode = gameCenterDetail?.language || LanguageCode.English;
  if (languageCode === LanguageCode.Null) {
    languageCode = LanguageCode.English;
  }

  const { organizationId, brandId } = gameCenterDetail;

  const [isLoading, setIsLoading, gameTerms, setGameTerms] = useGameTermStore((store) => [
    store.isLoading,
    store.setIsLoading,
    store.gameTerms,
    store.setGameTerms,
  ]);

  const loadGameTerms = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const result = await getGameTerms(languageCode, organizationId, brandId);
      setGameTerms(result.data);
    } catch {
      // eslint-disable-next-line no-console
      console.log('Error loading GameTerm');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!gameTerms || gameTerms.language !== languageCode) {
      loadGameTerms();
    }
  }, [gameCenterDetail.gameCenterId]);

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 z-50 h-screen overflow-y-auto bg-white p-8 text-black">
      {isLoading ? (
        <CircleLoader.Container />
      ) : (
        <>
          <div className="mt-6 mb-3">
            <img src={gameTerms?.logoUrl || DEFAULT_LOGO_URL} width="20%" height="auto" alt="flarie_logo" />
          </div>
          <div className="whitespace-pre-wrap break-words pt-4">
            {gameTerms && gameTerms.flarieTermText ? (
              <RenderHtmlText htmlText={gameTerms.flarieTermText} />
            ) : (
              <StaticTerms />
            )}
          </div>
          <div className="mb-4 flex w-full justify-center text-center">
            <a
              className="mt-4 min-w-[173px] rounded-md bg-gradient-button p-[10px] text-white no-underline"
              href={gameTerms?.buttonUrl || 'https://www.flarie.com'}
              target="_blank"
              rel="noreferrer"
            >
              {gameTerms?.buttonText || 'LEARN MORE ABOUT FLARIE'}
            </a>
          </div>
          <div className="pb-12">
            <hr></hr>
            <div className="my-4 whitespace-pre-wrap break-words">
              <RenderHtmlText htmlText={gameTerms?.organizationTermText || ''} />
            </div>
            {additionalTermsText && (
              <div className="whitespace-pre-wrap break-words pt-6 pb-3">
                <RenderHtmlText htmlText={additionalTermsText} />
              </div>
            )}
          </div>
          <div role="button" onClick={onClose} className="sticky bottom-[60px] mt-10 flex w-full justify-center">
            <p className="w-[45px] h-[45px] rounded-full bg-black p-4 text-center flex items-center justify-center">
              <CloseIcon color='#FFFFFF' />
            </p>
          </div>
        </>
      )}
    </div>
  );
};
