import { FormInputType } from '@flarie/common';

export const getPropertyKeyFromType = (type: FormInputType): string => {
  const keyValuePair = {
    [FormInputType.UUID]: 'uuid',
    [FormInputType.USERNAME]: 'username',
    [FormInputType.EMAIL]: 'email',
    [FormInputType.FIRST_NAME]: 'firstName',
    [FormInputType.LAST_NAME]: 'lastName',
    [FormInputType.FULL_NAME]: 'fullName',
    [FormInputType.COMPANY]: 'company',
    [FormInputType.PHONE]: 'phone',
    [FormInputType.AGE]: 'age',
    [FormInputType.GENDER]: 'gender',
    [FormInputType.BIRTHDAY]: 'birthday',
    [FormInputType.ADDRESS]: 'address',
    [FormInputType.COUNTRY]: 'country',
    [FormInputType.CITY]: 'city',
    [FormInputType.SRC]: 'src',
    [FormInputType.ZIP_CODE]: 'zipCode',
    [FormInputType.BUSINESS_REGION]: 'businessRegion',
    [FormInputType.OTHER1]: 'other1',
    [FormInputType.OTHER2]: 'other2',
    [FormInputType.OTHER3]: 'other3',
    [FormInputType.OTHER4]: 'other4',
    [FormInputType.CONSENT]: 'consent',
    [FormInputType.CONSENT_MANDATORY]: 'consentMandatory',
    [FormInputType.PARAM1]: 'param1',
    [FormInputType.PARAM2]: 'param2',
    [FormInputType.PARAM3]: 'param3',
    [FormInputType.PARAM4]: 'param4',
    [FormInputType.PARAM5]: 'param5',
    [FormInputType.PARAM6]: 'param6',
    [FormInputType.PARAM7]: 'param7',
    [FormInputType.PARAM8]: 'param8',
    [FormInputType.PARAM9]: 'param9',
    [FormInputType.PARAM10]: 'param10',
  };

  return keyValuePair[type];
};
