/* eslint-disable no-case-declarations */
import {
  DynamoDbEntityType,
  IGameCenterAdModule,
  IGameCenterGameModule,
  IGameCenterReward,
  IGameCenterTextModule,
} from '@flarie/common';
import AdSection from 'container/ad-section/AdSection';
import GameSection from 'container/game-section/GameSection';
import RewardSection from 'container/reward-section/RewardSection';
import TextSection from 'container/text-section/TextSection';
import { IModuleType } from 'interfaces/ModuleType';

interface Props {
  modules: Array<IModuleType>;
  isWalletActive: boolean;
}

export const ModuleList = ({ modules = [], isWalletActive = true }: Props) => {
  return (
    <div className="grid grid-cols-2 gap-x-5 px-8 ">
      {modules.map((module: IModuleType) => (
        <ModuleList.Item module={module} isWalletActive={isWalletActive} key={module.id} />
      ))}
      <div className='h-[148px]'></div>
    </div>
  );
};

ModuleList.Item = ({ module, isWalletActive }: { module: IModuleType; isWalletActive: boolean }) => {
  switch (module.entityType as unknown as DynamoDbEntityType) {
    case DynamoDbEntityType.GAME_CENTER_GAME_MODULE:
      const gameModule = { ...module } as unknown as IGameCenterGameModule;
      return <GameSection {...gameModule} />;
    case DynamoDbEntityType.GAME_CENTER_AD_MODULE:
      const adModule = { ...module } as unknown as IGameCenterAdModule;
      return <AdSection {...adModule} />;
    case DynamoDbEntityType.GAME_CENTER_TEXT_MODULE:
      const textModule = { ...module } as unknown as IGameCenterTextModule;
      return <TextSection {...textModule} />;
    case DynamoDbEntityType.GAME_CENTER_REWARD_MODULE:
      if (!isWalletActive) {
        return <div />;
      }
      const rewardModule = { ...module } as unknown as any;
      return <RewardSection {...rewardModule} rewards={rewardModule?.rewards || ([] as IGameCenterReward[])} />;
    default:
      return <div>Feature not implemented yet</div>;
  }
};
